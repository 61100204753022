import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { addParties, updateParties } from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import Select from "../../Select";
import Label from "../AddCardForm/Label";
import ToggleButtonGray from "../../buttons/ToggleButton/ToggleButtonGray";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import { randomNumberRange } from "../../../utils";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import {criminalPartyRoles, partyRoles} from "../../../containers/BaseApp/Cases/partyTypesAndRoles";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import {
  Bold14Font,
  Bold18Font,
  Medium18Font,
} from "../../FontsNewComponent/Fonts";

export default function PartiesForm(prop) {
  let { object, id_case, caseObject } = prop;
  const dispatch = useDispatch();
  const [name, setName] = useState(object.name ?? "");
  const [role, setRole] = useState(object.role ?? "");
  const [client, setClient] = useState(object.client ?? false);
  const [isLoading, setIsLoading] = useState(false);
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  let form = React.createRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      if (!role) {
        handleErrorMessage("Select role", dispatch);
        return;
      }
      setIsLoading(true);
      let resp;
      resp = {
        id: object.id ?? `new_${Math.random()}`,
        name,
        role,
        client,
      };
      if (client && caseObject) {
        const parties = [...caseObject.parties, ...[resp]];
        let client_roles = [];
        parties.forEach((v) => {
          if (v.client && client_roles.indexOf(v.role) === -1) {
            client_roles.push(v.role);
          }
        });
        if (client_roles.length > 1) {
          setIsLoading(false);
          handleErrorMessage(
            "Clients cannot appear on opposite sides of case",
            dispatch
          );
          return;
        }
      }
      setIsLoading(false);
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update"
        );
      }
    }
  };

  const onRoleChange = (selectedOption) => {
    setRole(selectedOption.value);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Input
            maxLength={125}
            required
            label={"Name"}
            onChangeHandler={(e) => {
              if (e.target.value.length <= 125) setName(e.target.value);
            }}
            autoFocus
            value={name}
            placeholder={"Enter name"}
            name={"name"}
            form={`form-party-${object.id ?? randomNumber}`}
          />
        </div>
        <div className="form-group">
          <Label name={"Role"} />
          {!object.id ? (
            <SelectAnotherTry options={caseObject.new_case_type === 'Criminal' ? criminalPartyRoles : partyRoles} onChange={onRoleChange} />
          ) : (
            <Medium18Font
              as="div"
              className="pl-3"
              style={{ marginTop: "-10px" }}
            >
              {role}
            </Medium18Font>
          )}
        </div>
        <div className="form-group">
          <Label name={"Client"} />
          <div className="container-fluid">
            <ToggleButtonGray
              checked={client}
              onChange={(e) => {
                setClient(e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <DividerHorizontal />
        <div className="form-group">
          <div className="container-fluid">
            <form
              ref={form}
              onSubmit={handleSubmit}
              id={`form-party-${object.id ?? randomNumber}`}
            >
              <Button
                clickHandler={handleSubmit}
                loading={isLoading}
                disabled={isLoading}
                wide
                primary
              >
                Save
              </Button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

PartiesForm.propTypes = {
  object: PropTypes.object,
  id_case: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};
