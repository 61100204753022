import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import BounceLoader from "react-spinners/BounceLoader";
import rem from "../../../../utils/rem";

import BaseAppLayoutLeftSideBarStyled from "./styled/BaseAppLayoutLeftSideBarStyled";
import RectangleButton from "../../../buttons/RectangleButton";
import Logo from "../../../Logo";
import LeftSideBarMenuButton from "../../../buttons/LeftSideBarMenuButton";
import BaseAppLayoutLeftSidebarMenuIconStyled from "./styled/BaseAppLayoutLeftSidebarMenuIconStyled";
import LeftSideBarUserLayout from "../../LeftSideBarUserLayout";

import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../../FontsNewComponent/Fonts";
import { useDispatch, useSelector } from "react-redux";
import {
  HIDE_LEFT_SIDE_BAR,
  SET_LEFT_SIDE_BAR_ACTIVE_ITEM,
  TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR,
  TOGGLE_LEFT_SIDE_BAR,
} from "../../../../redux/types";
import { route } from "../../../../routes";
import {
  getAdminCasesTotal,
  getCasesTotal,
} from "../../../../containers/Auth/auth";
import BaseAppLayoutLeftSidebarMenuBadgeStyled from "./styled/BaseAppLayoutLeftSidebarMenuBadgeStyled";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import useWindowSize from "../../../../hooks/useWindowSize";

export const DASHBOARD = "DASHBOARD";
export const DOCUMENTS = "DOCUMENTS";
export const APPLICATIONS = "APPLICATIONS";
export const TRIAL = "TRIAL";
export const PROOFS = "PROOFS";
export const CASES = "CASES";
export const HELP = "HELP";
export const USER_LIST = "USER_LIST";
export const PAGES = "PAGES";
export const TUTORIALS = "TUTORIALS";
export const SETTINGS = "SETTINGS";

const BaseAppLayoutLeftSideBar = ({ theme }) => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const canClose = useSelector((state) => state.leftSideBar.canClose);
  const activeMenuItem = useSelector((state) => state.leftSideBar.activeItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [casesTotal, setCasesTotal] = useState("");
  const ref = useRef(null);
  const { width } = useWindowSize();
  const onClickOutsideLeftSideBarHandler = () => {
    if (canClose && width >= 768) {
      dispatch({ type: TOGGLE_LEFT_SIDE_BAR });
    }
  };
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [forceShowMenu, setForceShowMenu] = useState(false);
  const onEnterLeaveToggleCloseLeftSideBarHandler = () => {
    dispatch({ type: TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR });
  };

  useOnClickOutside(ref, onClickOutsideLeftSideBarHandler);

  const menuItems = !is_admin
    ? [
        {
          id: 1,
          iconClassName: "app-dashboard-menu-icon",
          text: "Dashboard",
          badge: false,
          activeType: DASHBOARD,
        },
        {
          id: 2,
          iconClassName: "app-cases-menu-icon",
          text: "Cases",
          badge: true,
          activeType: CASES,
        },
        {
          id: 3,
          iconClassName: "app-help-menu-icon",
          text: "Help",
          badge: false,
          activeType: HELP,
        },
      ]
    : [
        {
          id: 1,
          iconClassName: "app-dashboard-menu-icon",
          text: "Dashboard",
          badge: false,
          activeType: DASHBOARD,
        },
        {
          id: 2,
          iconClassName: "app-cases-menu-icon",
          text: "All Cases",
          badge: true,
          activeType: CASES,
        },
        {
          id: 3,
          iconClassName: "app-cases-menu-icon",
          text: "User List",
          badge: false,
          activeType: USER_LIST,
        },
        {
          id: 4,
          iconClassName: "app-cases-menu-icon",
          text: "Pages",
          badge: false,
          activeType: PAGES,
        },
        {
          id: 5,
          iconClassName: "app-help-menu-icon",
          text: "Tutorials",
          badge: false,
          activeType: TUTORIALS,
        },
        {
          id: 6,
          iconClassName: "app-help-menu-icon",
          text: "Settings",
          badge: false,
          url: route.admin.baseApp.settings.causesOfAction,
          activeType: SETTINGS,
        },
      ];
  const cateItems = [
    {
      id: 10,
      iconClassName: "app-dashboard-menu-icon",
      text: "Trial Hub",
      badge: false,
      url: "trial-hub",
      activeType: TRIAL,
    },
    {
      id: 11,
      iconClassName: "app-cases-menu-icon",
      text: "Document Hub",
      badge: false,
      url: "documents",
      activeType: DOCUMENTS,
    },
    {
      id: 12,
      iconClassName: "app-help-menu-icon",
      text: "Applications Hub",
      badge: false,
      url: "applications-hub",
      activeType: APPLICATIONS,
    },
    {
      id: 13,
      iconClassName: "app-help-menu-icon",
      text: "Trial Proofs",
      badge: false,
      url: "trial-proofs",
      activeType: PROOFS,
    },
  ];
  useEffect(() => {
    const getNumberOfCases = async () => {
      if (!is_admin) {
        const resp = await getCasesTotal(dispatch);
        if (resp) {
          setCasesTotal(resp);
        } else {
          setCasesTotal("0");
        }
      } else {
        const resp = await getAdminCasesTotal(dispatch);
        if (resp) {
          setCasesTotal(resp);
        } else {
          setCasesTotal("0");
        }
      }
    };
    getNumberOfCases();
  }, []);

  const hideLeftSideBarHandler = () => {
    dispatch({ type: HIDE_LEFT_SIDE_BAR });
  };

  const setActiveItem = (element) => {
    dispatch({
      type: SET_LEFT_SIDE_BAR_ACTIVE_ITEM,
      payload: element.activeType,
    });
  };
  useEffect(() => {
    if (
      history.location &&
      history.location.pathname &&
      history.location.pathname.indexOf("/app/cases/") !== -1
    ) {
      setShowMainMenu(false);
    } else {
      setShowMainMenu(true);
    }
  }, [history.location.pathname]);
  const onMenuButtonClickHandler = (element) => {
    setActiveItem(element);
    if (!element.url) {
      history.push(
        `${
          !is_admin ? route.baseApp.app : route.admin.baseApp.app
        }/${element.text.toLowerCase().replace(" ", "-")}`
      );
    } else {
      history.push(element.url);
    }
  };
  const onButtonClickHandler = (element) => {
    setActiveItem(element);
    const url = history.location.pathname.match(/\/(cases|cases\/edit)\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/);
    history.push(
      `${!is_admin ? route.baseApp.cases : route.admin.baseApp.cases}/${url[2]}/${element.url}`
    );
    hideLeftSideBarHandler();
  };

  return (
    <div
      onMouseEnter={onEnterLeaveToggleCloseLeftSideBarHandler}
      onMouseLeave={onEnterLeaveToggleCloseLeftSideBarHandler}
      ref={ref}
    >
      <BaseAppLayoutLeftSideBarStyled>
        <div>
          {/* Logo with close btn */}
          <div
            style={{
              height: rem("82px"),
              borderBottom: `1px solid ${theme.colors.grayOpacity}`,
              marginBottom: "36px",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-end"
              style={{ maxWidth: rem("194px") }}
            >
              <RectangleButton lsbCloseBtn onClick={hideLeftSideBarHandler} />
              <Logo white />
            </div>
          </div>
          {/* Menu items */}
          {!forceShowMenu && !showMainMenu ? (
            <div
              style={{
                height: rem("82px"),
                borderBottom: `1px solid ${theme.colors.grayOpacity}`,
                marginBottom: "36px",
              }}
            >
              <LeftSideBarMenuButton
                className={"justify-content-start d-flex"}
                onClick={() => {
                  setForceShowMenu(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <g transform={"rotate(0 12 12)"}>
                      <g>
                        <g opacity=".1">
                          <path
                            fill="#cbcfee"
                            d="M0 8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8z"
                          />
                        </g>
                        <g transform="rotate(-270 12 12)">
                          <path
                            fill="none"
                            stroke="#374ac2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="20"
                            strokeWidth="1.5"
                            d="M10 11v0l2 2v0l2-2v0"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <Bold18Font
                  className={"ml-2"}
                  textColor={theme.colors.whiteOpacity}
                >
                  Back to menu
                </Bold18Font>
              </LeftSideBarMenuButton>
            </div>
          ) : null}
          <ul className="d-flex flex-column align-items-center">
            {showMainMenu || forceShowMenu
              ? menuItems.map((el) => (
                  <li key={el.id} className="mb-4">
                    <LeftSideBarMenuButton
                      isActive={el.activeType === activeMenuItem}
                      onClick={() => onMenuButtonClickHandler(el)}
                    >
                      <div className="d-flex align-items-center">
                        <BaseAppLayoutLeftSidebarMenuIconStyled
                          className={`${el.iconClassName} mr-4`}
                        />
                        <Bold18Font textColor={theme.colors.whiteOpacity}>
                          {el.text}
                        </Bold18Font>
                      </div>
                      {el.badge ? (
                        <Fragment>
                          {casesTotal ? (
                            <BaseAppLayoutLeftSidebarMenuBadgeStyled>
                              <Bold14Font textColor={theme.colors.white}>
                                {casesTotal}
                              </Bold14Font>
                            </BaseAppLayoutLeftSidebarMenuBadgeStyled>
                          ) : (
                            <BounceLoader
                              size={30}
                              color={theme.colors.whiteOpacity}
                            />
                          )}
                        </Fragment>
                      ) : null}
                    </LeftSideBarMenuButton>
                  </li>
                ))
              : cateItems.map((el) => {
                  return (
                    <li key={el.id} className="mb-4">
                      <LeftSideBarMenuButton
                        isActive={el.activeType === activeMenuItem}
                        onClick={() => onButtonClickHandler(el)}
                      >
                        <div className="d-flex align-items-center">
                          <Bold18Font textColor={theme.colors.whiteOpacity}>
                            {el.text}
                          </Bold18Font>
                        </div>
                      </LeftSideBarMenuButton>
                    </li>
                  );
                })}
            {!is_admin ? (
              <li className="d-flex flex-column justify-content-end align-items-center">
                <Medium14Font style={{ color: theme.colors.white }}>
                  Need help? Email us at
                </Medium14Font>
                <Bold14Font>
                  <a
                    href="mailto:support@trialproofer.ie"
                    style={{
                      color: theme.colors.cyanBorder,
                      textDecoration: "underline",
                    }}
                  >
                    support@trialproofer.ie
                  </a>
                </Bold14Font>
              </li>
            ) : null}
          </ul>
        </div>

        {/* User menu */}
        <div className="d-flex justify-content-center mb-4">
          <LeftSideBarUserLayout />
        </div>
      </BaseAppLayoutLeftSideBarStyled>
    </div>
  );
};

export default withTheme(BaseAppLayoutLeftSideBar);
