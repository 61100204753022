import {
  HIDE_RIGHT_SIDE_BAR,
  RESET_RIGHT_SIDE_BAR_TITLE,
  SET_AFTERCLOSE_ACTION,
  SET_CUSTOM_CLOSE_ACTION,
  SET_EDITABLE_OBJECT,
  SET_RIGHT_SIDE_BAR_TITLE,
  SHOW_RIGHT_SIDE_BAR,
} from "../types";

const initialState = {
  isSideBarPulled: false,
  title: "",
  content: null,
  url: null,
  swapContent: false,
  editableObject: false,
  afterCloseAction: false,
  customCloseAction: false,
};

const rightSideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_RIGHT_SIDE_BAR:
      return {
        ...state,
        editableObject: action.editableObject ?? false,
        swapContent: action.swapContent ?? false,
        afterCloseAction: action.afterCloseAction ?? false,
        isSideBarPulled: true,
        content: action.content,
        title: action.title,
        url: action.url,
      };

    case HIDE_RIGHT_SIDE_BAR:
      if (state.url !== window.location.pathname) {
        return initialState;
      }
      if (state.swapContent) {
        if (state.afterCloseAction) {
          state.afterCloseAction();
        }
        if (Array.isArray(state.swapContent.content)) {
          const lastIndex = state.swapContent.content.length - 1;
          if (lastIndex >= 0) {
            const listCopy = [...state.swapContent.content];
            listCopy.pop();
            return {
              ...state,
              swapContent: {
                ...state.swapContent,
                content: [...listCopy],
              },
              content: state.swapContent.content[lastIndex],
              title: state.swapContent.title[lastIndex],
            };
          }
        } else {
          let title_ = state.title;
          if (state.swapContent.title) {
            if (state.swapContent.title === "{editableObject}") {
              title_ = state.editableObject.label;
            } else {
              title_ = state.swapContent.title;
            }
          }
          return {
            ...state,
            swapContent:
              state.swapContent && state.swapContent.swapContent
                ? state.swapContent.swapContent
                : false,
            content: state.swapContent.content,
            title: title_,
          };
        }
      }
      return {
        ...state,
        editableObject: false,
        isSideBarPulled: false,
        content: initialState.content,
        title: initialState.title,
        url: null,
      };

    case SET_EDITABLE_OBJECT:
      return { ...state, editableObject: action.editableObject };

    case SET_AFTERCLOSE_ACTION:
      return { ...state, afterCloseAction: action.payload };

    case SET_CUSTOM_CLOSE_ACTION:
      return { ...state, customCloseAction: action.payload };

    case SET_RIGHT_SIDE_BAR_TITLE:
      return { ...state, title: action.payload };

    case RESET_RIGHT_SIDE_BAR_TITLE:
      return { ...state, title: initialState.title };

    default:
      return state;
  }
};

export default rightSideBarReducer;
