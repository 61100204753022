import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch, useSelector } from "react-redux";
import { updateSubEvidence } from "../../../containers/Auth/auth";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { theme } from "../../../styled-components/Theme/Theme";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import styled from "styled-components/macro";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  SET_EDITABLE_OBJECT,
  SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useHistory, useParams } from "react-router-dom";
import AssociateElementsThemesIssuesForm from "../EvidenceForms/AssociateElementsThemesIssuesForm";
import { format, parseISO } from "date-fns";
import SubEvidence from "../SubEvidence/SubEvidence";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";
import moment from "moment";
import SubEvidenceFurtherDetailsForm from "./SubEvidenceFurtherDetailsForm";
import InputNew from "../../InputNew/InputNew";
import TextAreaSideBar from "./TextAreaSideBar";
import RsbTabsMenu from "../../RSBTabsMenu";
import AssociateWitnessForm from "../EvidenceForms/AssociateWitnessForm";
import EvidenceObjectivesForm from "./EvidenceObjectivesForm";
import DocPropertiesLabel from "../../RSBTabsMenu/DocPropertiesLabel";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;
const menuTabs = [
  { title: "Elements" },
  { title: "Themes" },
  { title: "Issues" },
];
const SubEvidenceDocProperties = ({
  callback,
  caseId,
  sub_evidence,
  documentId,
  annotManager,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const evidence = useSelector((state) => state.rightSideBar.editableObject);
  const [witnesses, setWitnesses] = useState({});
  const form = useRef(null);
  const [activeMenuTab, setActiveMenuTab] = useState(0);
  const caseObject = useSelector((state) => state.data.store.case);
  const showWitness = useSelector((state) => state.app.showWitness);
  const showRelevance = useSelector((state) => state.app.showRelevance);
  const showObjectives = useSelector((state) => state.app.showObjectives);
  const showNotes = useSelector((state) => state.app.showNotes);
  const showFurtherDetails = useSelector((state) => state.app.showFurtherDetails);
  const evidenceWithCase = () => {
    const evidenceCopy = evidence;
    if (!evidenceCopy.case) {
      evidenceCopy.case = { ...caseObject };
    }
    return evidenceCopy;
  };
  useEffect(() => {
    let data = {};
    if (evidence.witnesses) {
      evidence.witnesses.forEach((v) => {
        if (!data[v.type]) {
          data[v.type] = [];
        }
        data[v.type].push(v);
        setWitnesses(data);
      });
    }
  }, [evidence.witnesses]);
  const editButton = (type_) => {
    let title_ = "Flag Relevance";
    return (
      <ListActionButton
        clickHandler={() => {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: evidence,
            content: (
              <AssociateElementsThemesIssuesForm
                key={evidence.id}
                type={type_}
                addToSwapContent={[
                  <SubEvidence
                    caseObject={caseObject}
                    callback={callback}
                    annotManager={annotManager}
                    sub_evidence={sub_evidence}
                    caseId={caseId}
                    documentId={documentId}
                  />,
                  <SubEvidenceDocProperties
                    callback={callback}
                    sub_evidence={sub_evidence}
                    annotManager={annotManager}
                    caseObject={caseObject}
                    documentId={documentId}
                    caseId={caseId}
                  />,
                ]}
                evidenceObject={evidenceWithCase()}
                setEvidenceObject={updateObject}
                evidenceId={evidence.id}
                caseObject={caseObject}
                caseId={caseObject.id}
              />
            ),
            swapContent: {
              content: [
                <SubEvidence
                  caseObject={caseObject}
                  callback={callback}
                  sub_evidence={sub_evidence}
                  annotManager={annotManager}
                  caseId={caseId}
                  documentId={documentId}
                />,
                <SubEvidenceDocProperties
                  callback={callback}
                  sub_evidence={sub_evidence}
                  annotManager={annotManager}
                  caseObject={caseObject}
                  documentId={documentId}
                  caseId={caseId}
                />,
              ],
              title: ["Key Sections", "Edit Key Section"],
            },
            title: title_,
          });
        }}
        type={"edit"}
      />
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const data_ = {
        causes_of_action_parent: evidence.causes_of_action_parent,
        causes_of_action: evidence.causes_of_action,
        causes_of_action_defensive: evidence.causes_of_action_defensive,
        themes_parent: evidence.themes_parent,
        themes: evidence.themes,
        subtype: evidence.subtype,
        issues_parent: evidence.issues_parent,
        witnesses: evidence.witnesses,
        issues: evidence.issues,
        objectives: evidence.objectives,
        notes: evidence.notes,
        date: evidence.date ? moment(evidence.date).format("YYYY-MM-DD") : "",
        summary: evidence.summary,
        preempt: evidence.preempt,
        from: evidence.from,
        to: evidence.to,
        type: evidence.type,
        label: evidence.label,
        priority: evidence.priority,
      };

      let resp = await updateSubEvidence(
        evidence.real_id ?? evidence.id,
        evidence.case ? evidence.case.id : caseId,
        documentId,
        dispatch,
        data_
      );
      resp.sub_evidence.forEach((v) => {
        v.case = caseObject;
      });
      callback(resp);
      setIsLoading(false);
    }
  };
  const updateObject = (resp) => {
    let obj = { ...evidence, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };
  const updateCore = (core_book) => {
    let obj = { ...evidence, ...{ core_book: core_book ? 1 : 0 } };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };
  const updateLabel = (event) => {
    let obj = { ...evidence, ...{ label: event.target.value } };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };
  const onChangeTextFieldHandler = (event) => {
    let obj = { ...evidence, ...{ notes: event.target.value } };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };
  const setEditBtnType = (activeTab) => {
    switch (activeTab) {
      case 0:
        return "causes_of_action";
      case 1:
        return "themes";
      case 2:
        return "issues";

      default:
        throw new Error("Wrong tab index");
    }
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            placeholder={"Label"}
            name={"label"}
            value={evidence.label}
            onChangeHandler={updateLabel}
          />
        </div>
        <Separator />
        <div className="form-group mb-0">
          <DocPropertiesLabel
            name={"Witnesses"}
            down={showWitness}
            clickHandler={() => {
              dispatch({
                type: SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
                payload: {
                  showWitness: !showWitness,
                },
              });
            }}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <AssociateWitnessForm
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseId}
                        returnFunction={updateObject}
                      />
                    ),
                    swapContent: {
                      content: [
                        <SubEvidence
                          caseObject={caseObject}
                          callback={callback}
                          annotManager={annotManager}
                          caseId={caseId}
                          sub_evidence={sub_evidence}
                          documentId={documentId}
                        />,
                        <SubEvidenceDocProperties
                          callback={callback}
                          sub_evidence={sub_evidence}
                          annotManager={annotManager}
                          caseObject={caseObject}
                          documentId={documentId}
                          caseId={caseId}
                        />,
                      ],
                      title: ["Key Sections", "Edit Key Section"],
                    },
                    title: "Witness",
                  });
                }}
                type={"edit"}
              />
            }
          >
            {Object.keys(witnesses).map((w_k) => {
              return !witnesses[w_k].length ? null : (
                <div className="container-fluid">
                  <div className="mb-2">
                    <Medium14Font textColor={theme.colors.darkOpacity}>
                      {w_k}
                    </Medium14Font>
                  </div>
                  <div className="d-flex flex-column">
                    {witnesses[w_k].map((witness_, index) => {
                      return (
                        <Medium14Font
                          className={index > 0 ? "ml-2" : ""}
                          textColor={theme.colors.dark}
                        >
                          {witness_.name}
                        </Medium14Font>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </DocPropertiesLabel>
        </div>
        <Separator />
        <div className="form-group mb-0">
          <DocPropertiesLabel
            down={showRelevance}
            clickHandler={() => {
              dispatch({
                type: SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
                payload: {
                  showRelevance: !showRelevance,
                },
              });
            }}
            name={"Relevance"}
            button={editButton(setEditBtnType(activeMenuTab))}
          >
            <div className="form-group d-flex mb-4 pr-3">
              <RsbTabsMenu
                tabs={menuTabs}
                activeMenuTab={activeMenuTab}
                setActiveMenuTab={setActiveMenuTab}
              />
            </div>
            {activeMenuTab === 0 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowCoa object={evidence} case_object={caseObject} />
                </div>
              </div>
            ) : null}
            {activeMenuTab === 1 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowThemes object={evidence} case_object={caseObject} />
                </div>
              </div>
            ) : null}
            {activeMenuTab === 2 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowIssues object={evidence} case_object={caseObject} />
                </div>
              </div>
            ) : null}
          </DocPropertiesLabel>
        </div>
        <Separator />
        <div className="form-group mb-0">
          <DocPropertiesLabel
            down={showObjectives}
            clickHandler={() => {
              dispatch({
                type: SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
                payload: {
                  showObjectives: !showObjectives,
                },
              });
            }}
            name={"Objectives"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <EvidenceObjectivesForm
                        key={evidence.id}
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseId}
                        fromDocs
                      />
                    ),
                    swapContent: {
                      content: [
                        <SubEvidence
                          caseObject={caseObject}
                          callback={callback}
                          annotManager={annotManager}
                          caseId={caseId}
                          sub_evidence={sub_evidence}
                          documentId={documentId}
                        />,
                        <SubEvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          sub_evidence={sub_evidence}
                          caseId={caseId}
                          documentId={documentId}
                          annotManager={annotManager}
                        />,
                      ],
                      title: ["Key Sections", "Edit Key Section"],
                    },
                    title: "Objectives",
                  });
                }}
                type={"edit"}
              />
            }
          >
            {evidence.objectives
              ? evidence.objectives.map((obj, index) => {
                  return (
                    <div key={index} className="container-fluid">
                      <Medium14Font textColor={theme.colors.darkOpacity}>
                        {index + 1}. {obj.name}
                      </Medium14Font>
                    </div>
                  );
                })
              : null}
          </DocPropertiesLabel>
        </div>
        <Separator />
        <div className="form-group mb-0">
          <DocPropertiesLabel
            down={showNotes}
            clickHandler={() => {
              dispatch({
                type: SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
                payload: {
                  showNotes: !showNotes,
                },
              });
            }}
            name={"Notes/Comments"}
          >
            <TextAreaSideBar
              withMargin
              placeholder={"Enter Notes/Comments"}
              value={evidence.notes}
              name={"notes"}
              onChange={onChangeTextFieldHandler}
            />
          </DocPropertiesLabel>
        </div>
        <Separator />
        <div className="form-group mb-0">
          <DocPropertiesLabel
            name={"Further Details"}
            down={showFurtherDetails}
            clickHandler={() => {
              dispatch({
                type: SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
                payload: {
                  showFurtherDetails: !showFurtherDetails,
                },
              });
            }}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <SubEvidenceFurtherDetailsForm
                        withLabel={false}
                        key={evidence.id}
                        callback={callback}
                        evidenceObject={evidenceWithCase()}
                        setEvidenceObject={updateObject}
                        annotManager={annotManager}
                        documentId={documentId}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseObject.id}
                      />
                    ),
                    swapContent: {
                      content: [
                        <SubEvidence
                          caseObject={caseObject}
                          callback={callback}
                          sub_evidence={sub_evidence}
                          annotManager={annotManager}
                          caseId={caseId}
                          documentId={documentId}
                        />,
                        <SubEvidenceDocProperties
                          callback={callback}
                          sub_evidence={sub_evidence}
                          annotManager={annotManager}
                          caseObject={caseObject}
                          documentId={documentId}
                          caseId={caseId}
                        />,
                      ],
                      title: ["Key Sections", "Edit Key Section"],
                    },
                    title: "Further Details",
                  });
                }}
                type={"edit"}
              />
            }
          >
            {evidence.date ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Date: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.date
                      ? format(parseISO(evidence.date), "dd-MM-yyyy")
                      : ""}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
            {evidence.type ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Key Section Type: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.type}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
            {evidence.subtype ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Sub-Type: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.subtype}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
            {evidence.priority ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Priority: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.priority}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
            {evidence.summary ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Summary: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.summary}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
            {evidence.preempt ? (
              <Fragment>
                <div className="container-fluid">
                  <Bold14Font>Pre-empt: </Bold14Font>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {evidence.preempt}
                  </Medium14Font>
                </div>
              </Fragment>
            ) : null}
          </DocPropertiesLabel>
        </div>
      </div>
      <div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id={``} ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SubEvidenceDocProperties;
