import { setTagField } from "../utils/function";
import { Draggable } from "react-beautiful-dnd";
import React, { Fragment, useState } from "react";
import NoRecords from "./NoRecords";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "./FontsNewComponent/Fonts";
import { Link, useParams } from "react-router-dom";
import LiStyled from "./Table/Styled/LiStyled";
import { SHOW_RIGHT_SIDE_BAR } from "../redux/types";
import AssociateWitnessForm from "./forms/EvidenceForms/AssociateWitnessForm";
import TableNoStateContent from "./Table/TableNoStateContent";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { theme } from "../styled-components/Theme/Theme";
import {
  TagElementPurple,
  TagElementTurquoiseSecond,
} from "./TagElementStyled/TagElementStyled";

const Tables = ({
  checkIsXExamFlowIsFalse,
  selectedTab,
  url,
  data,
  filterKey,
  setNewOrder,
  updateObject,
  caseObject,
  witnessObject,
  filter,
  canDrag,
  previewEvidence,
  editNote,
}) => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const { id, witness_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState([]);
  const associateWitness = (object) => {
    object.id = object.fake_id;
    object.witnesses.forEach((v) => {
      if (v.pivot && v.pivot.type) {
        v.type = v.pivot.type;
      }
    });
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateWitnessForm
          key={object.id}
          evidenceObject={object}
          setEvidenceObject={updateObject}
          evidenceId={object.fake_id}
          caseObject={caseObject}
          caseId={id}
        />
      ),
      title: `${object.label} Witnesses`,
    });
  };
  const NOR = () => {
    if (!checkIsXExamFlowIsFalse()) {
      return (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            {selectedTab === 0 || selectedTab === 1 || selectedTab === 2 ? (
              <Fragment>
                You have not added{" "}
                {selectedTab === 0
                  ? "Topics"
                  : selectedTab === 1
                  ? "Themes"
                  : "Issues"}{" "}
                yet
                <br />
                Click{" "}
                <Link
                  to={`${url.replace(
                    "trial-proofs/x-exam-witness-proofs",
                    "trial-hub/witness/edit"
                  )}`}
                >
                  here
                </Link>{" "}
                to add them.
              </Fragment>
            ) : (
              ""
            )}
          </Bold18Font>
        </NoRecords>
      );
    } else {
      return (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            {selectedTab === 0 || selectedTab === 1 || selectedTab === 2 ? (
              <Fragment>
                You have not added{" "}
                {selectedTab === 0
                  ? "Evidence"
                  : selectedTab === 1
                  ? "Themes"
                  : "Issues"}{" "}
                yet
                <br />
                Click{" "}
                <Link
                  to={`${url.replace(
                    "trial-proofs/witness-proofs",
                    "trial-hub/witness/edit"
                  )}`}
                >
                  here
                </Link>{" "}
                to add them.
              </Fragment>
            ) : (
              ""
            )}
          </Bold18Font>
        </NoRecords>
      );
    }
  };
  const toggleShowAll = (id) => {
    let clone = [...showAll];
    if (showAll.indexOf(id) === -1) {
      clone.push(id);
    } else {
      clone = showAll.filter((v) => v !== id);
    }
    setShowAll(clone);
  };
  const witnessTableSettings = {
    collapsed: false,
    sortable: true,
    actions: [],
    setNewOrder: (params) => {
      let data_ = [...data];
      const index_ = data_.findIndex((v) => v.id === params.object_id);
      data_[index_].evidences = params.items;
      setNewOrder(data_);
    },
    fields: [
      {
        name: "id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        maxWidth: "70px",
      },
      {
        name: "label",
        width: "250px",
        label: checkIsXExamFlowIsFalse() ? "EVIDENCE" : "TOPIC",
        renderer: (object) => {
          return (
            <Fragment>
              <Medium14Font>{object.label}</Medium14Font>
              {object.date ? (
                <Fragment>
                  <br />
                  <Medium14Font>
                    {moment(object.date).format("DD-MM-YYYY")}
                  </Medium14Font>
                </Fragment>
              ) : null}
              {checkIsXExamFlowIsFalse() ? (
                <Fragment>
                  <br />
                  <Bold14Font
                    pinter
                    onClick={() => {
                      previewEvidence(object);
                    }}
                    textColor={"#374ac2"}
                  >
                    View
                  </Bold14Font>
                </Fragment>
              ) : null}
            </Fragment>
          );
        },
      },
      {
        name: "objectives",
        label: "OBJECTIVES & EXAM LINES",
        width: "200px",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            if (
              (checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
                witnessObject.exam_lines.evidences &&
                witnessObject.exam_lines.evidences.indexOf(object.id) === -1)
              ||
              (!checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
                witnessObject.exam_lines.topics &&
                witnessObject.exam_lines.topics.indexOf(object.id) === -1)
            ) {
              return "";
            }
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>
                      {objective.name}
                      {objective.exam_lines && objective.exam_lines.length
                        ? objective.exam_lines.map((examLine, idx) => (
                            <LiStyled key={idx} style={{ marginLeft: "20px" }}>
                              <Medium14Font>{examLine.name}</Medium14Font>
                            </LiStyled>
                          ))
                        : null}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "evidences",
        label: "KEY SECTIONS",
        renderer: (object, k) => {
          return (
            <Fragment>
              {showAll.indexOf(k) === -1 ? (
                <Medium14Font
                  underline
                  pointer
                  onClick={() => {
                    toggleShowAll(k);
                  }}
                >
                  {object.sub_evidence && object.sub_evidence.length ? object.sub_evidence.length : ""}
                </Medium14Font>
              ) : (
                object.sub_evidence.map((se, index) => {
                  return (
                    <TagElementPurple
                      pointer
                      onClick={() => {
                        history.push({
                          state: { subevidence: se.id },
                          pathname: `/${
                            is_admin ? "admin/all-cases" : "app/cases"
                          }/${caseObject.id}/documents/${object.file_id}`,
                        });
                      }}
                      key={index}
                    >
                      {se.label}
                    </TagElementPurple>
                  );
                })
              )}
            </Fragment>
          );
        },
      },
      {
        name: "tags",
        label: "TAGS",
        width: "350px",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div key={index}>{tag.element}</div>;
                })}
              </ul>
            );
          } else {
            return " ";
          }
        },
      },
      {
        name: "ref",
        label: "REF",
        width: "150px",
        renderer: (object) => {
          return (
            <div>
              <Medium14Font>{object.reference ?? " "}</Medium14Font>
            </div>
          );
        },
      },
      {
        name: "notes",
        label: "NOTE",
        width: "350px",
        renderer: (object) => {
          const items__ = object.witnesses.filter(
            (v) => v.fake_id !== parseInt(witness_id)
          );
          return (
            <Fragment>
              {items__.length ? (
                <Fragment>
                  {items__.map((v, i) => {
                    return (
                      <TagElementTurquoiseSecond key={i}>
                        {v.name}
                      </TagElementTurquoiseSecond>
                    );
                  })}
                </Fragment>
              ) : null}
              <Bold14Font
                pinter
                onClick={() => {
                  associateWitness(object);
                }}
                textColor={"#374ac2"}
              >
                Associate Witness
              </Bold14Font>
              {object.notes ? (
                <Fragment>
                  <br />
                  <Medium14Font breakWord>{object.notes}</Medium14Font>
                </Fragment>
              ) : null}
              <br />
              <Bold14Font
                pinter
                onClick={() => {
                  editNote(object);
                }}
                textColor={"#374ac2"}
              >
                Edit Note
              </Bold14Font>
            </Fragment>
          );
        },
        maxWidth: "412px",
      },
    ],
  };
  const xExamWitnessTableSettings = {
    collapsed: false,
    sortable: true,
    actions: [],
    setNewOrder: (params) => {
      let data_ = [...data];
      const index_ = data_.findIndex((v) => v.id === params.object_id);
      data_[index_].evidences = params.items;
      setNewOrder(data_);
    },
    fields: [
      {
        name: "id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        maxWidth: "70px",
      },
      {
        name: "label",
        label: "TOPIC",
        renderer: (object) => {
          return (
            <Fragment>
              <Medium14Font>{object.name}</Medium14Font>
            </Fragment>
          );
        },
      },
      {
        name: "label",
        label: "EVIDENCE",
        renderer: (object) => {
          return (
            <Fragment>
              {object.evidences.length ? (
                <ul>
                  {object.evidences.map((v, i) => {
                    return (
                      <li key={i}>
                        <Medium14Font>
                          {v.label}
                          <Bold14Font
                            pinter
                            className="ml-2"
                            onClick={() => {
                              previewEvidence(v);
                            }}
                            textColor={"#374ac2"}
                          >
                            View
                          </Bold14Font>
                        </Medium14Font>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </Fragment>
          );
        },
      },
      {
        name: "evidences",
        label: "KEY SECTIONS",
        renderer: (object) => {
          return (
            <Fragment>
              {showAll.indexOf(object.id) === -1 ? (
                <Medium14Font
                  underline
                  pointer
                  onClick={() => {
                    toggleShowAll(object.id);
                  }}
                >
                  {object.sub_evidence && object.sub_evidence.length ? object.sub_evidence.length : ""}
                </Medium14Font>
              ) : (
                object.sub_evidence.map((se, index) => {
                  return (
                    <TagElementPurple
                      pointer
                      onClick={() => {
                        history.push({
                          state: { subevidence: se.id },
                          pathname: `/${
                            is_admin ? "admin/all-cases" : "app/cases"
                          }/${caseObject.id}/documents/${object.file_id}`,
                        });
                      }}
                      key={index}
                    >
                      {se.label}
                    </TagElementPurple>
                  );
                })
              )}
            </Fragment>
          );
        },
      },
      {
        name: "objectives",
        label: "OBJECTIVES & EXAM LINES",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            if (
              (checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
                witnessObject.exam_lines.evidences &&
                witnessObject.exam_lines.evidences.indexOf(object.id) === -1)
              ||
              (!checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
                witnessObject.exam_lines.topics &&
                witnessObject.exam_lines.topics.indexOf(object.id) === -1)
            ) {
              return "";
            }
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>
                      {objective.name}
                      {objective.exam_lines && objective.exam_lines.length
                        ? objective.exam_lines.map((examLine, idx) => (
                            <LiStyled key={idx} style={{ marginLeft: "20px" }}>
                              <Medium14Font>{examLine.name}</Medium14Font>
                            </LiStyled>
                          ))
                        : null}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div key={index}>{tag.element}</div>;
                })}
              </ul>
            );
          } else {
            return " ";
          }
        },
      },
    ],
  };
  const title = (v) => {
    if (selectedTab === 1) {
      return `${v.theme ? v.theme.name + " - " : ""}${v.name}`;
    } else if (selectedTab === 0) {
      return `${
        v.causes_of_action
          ? v.causes_of_action.type.name + " - "
          : v.type
          ? v.type.name
          : ""
      }${v.name && v.type ? " - " : " "}${v.name ? v.name : ""}`;
    } else if (selectedTab === 2) {
      return `${v.issue ? v.issue.name + " - " : ""}${v.name}`;
    }
    return "";
  };
  if (!data || !data.length) {
    return <NOR />;
  }
  return canDrag
    ? data.filter(filterKey).map((v, i) => {
        return (
          <TableNoStateContent
            object_id={v.id}
            key={v.key}
            dataKey={v.key}
            canDrag={true}
            draggable_id={String(v.id)}
            divClass="row ml-0 mr-0"
            data={setTagField(caseObject, v.evidences.filter(filter))}
            title={title(v)}
            settings={
              checkIsXExamFlowIsFalse()
                ? witnessTableSettings
                : xExamWitnessTableSettings
            }
          />
        );
      })
    : data.filter(filterKey).map((v, i) => {
        return (
          <Draggable key={v.key} draggableId={v.key.toString()} index={i}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableNoStateContent
                  object_id={v.id}
                  dataKey={v.key}
                  draggable_id={String(v.id)}
                  canDrag={false}
                  divClass="row ml-0 mr-0"
                  data={setTagField(caseObject, v.evidences.filter(filter))}
                  title={title(v)}
                  settings={
                    checkIsXExamFlowIsFalse()
                      ? witnessTableSettings
                      : xExamWitnessTableSettings
                  }
                />
              </div>
            )}
          </Draggable>
        );
      });
};

export default Tables;
