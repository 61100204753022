import React, {Fragment, useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import RsbTabsMenu from "../../RSBTabsMenu";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox/Checkbox";
import {updateEvidence} from "../../../containers/Auth/auth";
import {
  DATA_ADD_TO_STORE,
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import {PulseLoader} from "react-spinners";
import {theme} from "../../../styled-components/Theme/Theme";
import CauseOfActionForm from "../CasesForms/CauseOfActionForm";
import ThemesForm from "../CasesForms/ThemesForm";
import IssuesForm from "../CasesForms/IssuesForm";
import co from "co";

const roles = [
  {label: "Plaintiff", value: "Plaintiff"},
  {label: "Defendant", value: "Defendant"},
];

const menuTabs = [
  {title: "Elements"},
  {title: "Themes"},
  {title: "Issues"},
];

const elementType = {
  COA: "coa",
  THEME: "theme",
  ISSUE: "issue",
};

const AssociateElementsThemesIssuesForm = ({
                                             evidenceObject,
                                             setEvidenceObject,
                                             addToSwapContent,
                                             selectedMenuTab,
                                             caseObject,
                                             sendData,
                                             type,
                                           }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeMenuTab, setActiveMenuTab] = useState(0);
  const [elementsParent, setElementsParent] = useState([]);
  const [elements, setElements] = useState([]);
  const [defenceElements, setDefenceElements] = useState([]);
  const [themesParent, setThemesParent] = useState([]);
  const [themes, setThemes] = useState([]);
  const [issuesParent, setIssuesParent] = useState([]);
  const [issues, setIssues] = useState([]);
  const [submitObject, setSubmitObject] = useState({});
  const caseObject_ = useSelector((state) => state.data.store.case);
  const dispatch = useDispatch();
  const history = useHistory();
  let form = React.createRef();
  let obj__ = caseObject ?? caseObject_;
  // set elements, themes and issues objects from evidenceObject data
  useEffect(() => {
    if (
      evidenceObject &&
      evidenceObject.causes_of_action &&
      evidenceObject.causes_of_action.length
    )
      setElements(evidenceObject.causes_of_action);
    if (
      evidenceObject &&
      evidenceObject.causes_of_action_defensive &&
      evidenceObject.causes_of_action_defensive.length
    )
      setDefenceElements(evidenceObject.causes_of_action_defensive);
    if (
      evidenceObject &&
      evidenceObject.causes_of_action_parent &&
      evidenceObject.causes_of_action_parent.length
    )
      setElementsParent(evidenceObject.causes_of_action_parent);
    if (
      evidenceObject &&
      evidenceObject.themes_parent &&
      evidenceObject.themes_parent.length
    )
      setThemesParent(evidenceObject.themes_parent);
    if (evidenceObject && evidenceObject.themes && evidenceObject.themes.length)
      setThemes(evidenceObject.themes);
    if (
      evidenceObject &&
      evidenceObject.issues_parent &&
      evidenceObject.issues_parent.length
    )
      setIssuesParent(evidenceObject.issues_parent);
    if (evidenceObject && evidenceObject.issues && evidenceObject.issues.length)
      setIssues(evidenceObject.issues);
  }, [evidenceObject]);

  // set active tab
  useEffect(() => {
    if (selectedMenuTab) {
      setActiveMenuTab(selectedMenuTab);
    }
    // set active tab when come from document properties
    if (type) {
      switch (type) {
        case "causes_of_action":
          setActiveMenuTab(0);
          break;
        case "themes":
          setActiveMenuTab(1);
          break;
        case "issues":
          setActiveMenuTab(2);
          break;
        default:
          setActiveMenuTab(0);
      }
    }
  }, []);

  // send data to server if submitObject changed
  useEffect(() => {
    if (submitObject && Object.keys(submitObject).length) {
      if (sendData) {
        sendData(submitObject);
      } else if (setEvidenceObject) {
        setEvidenceObject(submitObject);
        dispatch({type: HIDE_RIGHT_SIDE_BAR});
      }
    }
  }, [submitObject]);

  const onChangeCheckBoxHandler = (
    item,
    items,
    setFunction,
    parent_item,
    parent_list,
    setParentList,
    type,
    counterclaim,
  ) => {
    let findItem
    if (type === 'coa') {
      findItem = (element) => element.id === item.id && element.counterclaim === counterclaim;
    }  else {
      findItem = (element) => element.id === item.id;
    }
    const foundItem = items.find(findItem);
    if (!foundItem) {
      if (type === 'coa') {
        item.counterclaim = counterclaim;
      }
      setFunction((prevState) => [
        ...prevState,
        {
          // id: item.id,
          ...item,
        },
      ]);
      if (parent_item && parent_list && setParentList && type) {
        const fItem = parent_list.find(findItem);
        let total = 0;
        if (type === "coa") {
          total = items.filter((element) => {
            return (
              parent_item.elements.find((item) => {
                return element.id === item.id && item.counterclaim === counterclaim;
              }) ||
              parent_item.defence_elements.find((item) => {
                return element.id === item.id && item.counterclaim === counterclaim;
              })
            );
          }).length;
        }
        if (type === "issues") {
          total = items.filter((element) => {
            return parent_item.sub_themes.find((item) => {
              return element.id === item.id;
            });
          }).length;
        }
        if (type === "themes") {
          total = items.filter((element) => {
            return parent_item.sub_themes.find((item) => {
              return element.id === item.id;
            });
          }).length;
        }
        if (!fItem && total === 0) {
          let clone = [...parent_list];
          clone.push(parent_item);
          setParentList(clone);
        }
      }
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const tempSubmitObject = {
        causes_of_action: elements.length ? elements : [],
        causes_of_action_defensive: defenceElements.length
          ? defenceElements
          : [],
        causes_of_action_parent: elementsParent.length ? elementsParent : [],
        themes: themes.length ? themes : [],
        themes_parent: themesParent.length ? themesParent : [],
        issues: issues.length ? issues : [],
        issues_parent: issuesParent.length ? issuesParent : [],
      };

      setSubmitObject({...tempSubmitObject});
      setIsLoading(false);
    }
  };

  const setElementThemeIssueInCase = (resp, type) => {
    const caseObjectCopy = {...obj__};
    switch (type) {
      case elementType.COA:
        caseObjectCopy.causes_of_action.push(resp);
        break;

      case elementType.THEME:
        caseObjectCopy.themes.push(resp);
        break;

      case elementType.ISSUE:
        caseObjectCopy.issues.push(resp);
        break;

      default:
        throw new Error("Wrong type");
    }
    dispatch({
      type: DATA_ADD_TO_STORE,
      keyName: "case",
      data: caseObjectCopy,
    });
  };

  const onClickAddCOAHandler = (object) => {
    // console.log(evidenceObject);
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      // editableObject: caseObject,
      content: (
        <CauseOfActionForm
          key={evidenceObject.id}
          isCriminal={obj__.new_case_type === "Criminal"}
          caseObject={obj__}
          id_case={obj__.id}
          sendRequest
          object={object}
          afterSubmit={(resp) =>
            setElementThemeIssueInCase(resp, elementType.COA)
          }
          alreadySavedCOAList={obj__.causes_of_action}
        />
      ),
      swapContent: {
        content:
          addToSwapContent && addToSwapContent.length ? (
            [
              ...addToSwapContent,
              <AssociateElementsThemesIssuesForm
                evidenceObject={evidenceObject}
                setEvidenceObject={setEvidenceObject}
                selectedMenuTab={0}
                type={type}
                sendData={sendData}
              />,
            ]
          ) : (
            <AssociateElementsThemesIssuesForm
              evidenceObject={evidenceObject}
              setEvidenceObject={setEvidenceObject}
              selectedMenuTab={0}
              type={type}
              sendData={sendData}
            />
          ),
        title: "Flag Relevance",
      },
      title:
        obj__.new_case_type === "Criminal"
          ? "Add Offence"
          : "Add Cause of action",
    });
  };

  const onClickAddThemeHandler = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      // editableObject: caseObject,
      content: (
        <ThemesForm
          key={object.id}
          id_case={obj__.id}
          sendRequest
          object={{}}
          afterSubmit={(resp) =>
            setElementThemeIssueInCase(resp, elementType.THEME)
          }
        />
      ),
      swapContent: {
        content:
          addToSwapContent && addToSwapContent.length ? (
            [
              ...addToSwapContent,
              <AssociateElementsThemesIssuesForm
                evidenceObject={evidenceObject}
                setEvidenceObject={setEvidenceObject}
                selectedMenuTab={1}
                type={type}
                sendData={sendData}
              />,
            ]
          ) : (
            <AssociateElementsThemesIssuesForm
              evidenceObject={evidenceObject}
              setEvidenceObject={setEvidenceObject}
              selectedMenuTab={1}
              type={type}
              sendData={sendData}
            />
          ),
        title: "Flag Relevance",
      },
      title: "Add Theme",
    });
  };

  const onClickAddIssueHandler = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      // editableObject: caseObject,
      content: (
        <IssuesForm
          key={object.id}
          id_case={obj__.id}
          object={{}}
          sendRequest
          afterSubmit={(resp) =>
            setElementThemeIssueInCase(resp, elementType.ISSUE)
          }
        />
      ),
      swapContent: {
        content:
          addToSwapContent && addToSwapContent.length ? (
            [
              ...addToSwapContent,
              <AssociateElementsThemesIssuesForm
                evidenceObject={evidenceObject}
                setEvidenceObject={setEvidenceObject}
                selectedMenuTab={2}
                type={type}
                sendData={sendData}
              />,
            ]
          ) : (
            <AssociateElementsThemesIssuesForm
              evidenceObject={evidenceObject}
              setEvidenceObject={setEvidenceObject}
              selectedMenuTab={2}
              type={type}
              sendData={sendData}
            />
          ),
        title: "Flag Relevance",
      },
      title: "Add Issue",
    });
  };

  return (
    <Fragment>
      <div className="form-group mb-4">
        <RsbTabsMenu
          tabs={menuTabs}
          activeMenuTab={activeMenuTab}
          setActiveMenuTab={setActiveMenuTab}
        />
      </div>

      <div style={{height: "100%", overflowY: "auto"}}>
        {/* Elements */}
        {activeMenuTab === 0
          ? // [0, 1] array used to filter CAO by counterclaim value
          [0, 1].map((coa) => (
            <div className="form-group">
              <div className="container-fluid">
                <Bold14Font>
                  {coa === 0
                    ? obj__.new_case_type === "Criminal"
                      ? "Offence"
                      : "Cause of Action"
                    : "Counterclaim"}
                </Bold14Font>
                {evidenceObject || obj__
                  ? obj__.causes_of_action
                    .filter((item) => item.counterclaim === coa)
                    .map((element, index) => (
                      <Fragment>
                        <div key={index} style={{marginBottom: "30px"}}>
                          <div style={{marginBottom: "8px"}}>
                            <Checkbox
                              checked={elementsParent.find(
                                (el) => el.id === element.id && element.counterclaim === coa
                              )}
                              label={
                                <Bold14Font>{element.type.name}</Bold14Font>
                              }
                              onChange={() =>
                                onChangeCheckBoxHandler(
                                  element,
                                  elementsParent,
                                  setElementsParent
                                )
                              }
                            />
                          </div>
                          {element.elements && element.elements.length ? (
                            <div style={{marginLeft: "12px"}}>
                              {element.elements.length ? (
                                <Medium14Font>Elements</Medium14Font>
                              ) : null}
                              {element.elements.map((item, idx) => {
                                return <Checkbox
                                  key={idx}
                                  checked={elements.find(
                                    (e) => e.id === item.id && e.counterclaim === coa
                                  )}
                                  label={item.name}
                                  onChange={() =>
                                    onChangeCheckBoxHandler(
                                      item,
                                      elements,
                                      setElements,
                                      element,
                                      elementsParent,
                                      setElementsParent,
                                      "coa",
                                      coa,
                                    )
                                  }
                                  style={{marginLeft: "12px"}}
                                />
                              })}
                              {element.defence_elements.length ? (
                                <Medium14Font>
                                  Defence Elements
                                </Medium14Font>
                              ) : null}
                              {element.defence_elements.map((item, idx) => (
                                <Checkbox
                                  key={idx}
                                  checked={defenceElements.find(
                                    (element) => element.id === item.id && element.counterclaim === coa
                                  )}
                                  label={item.name}
                                  onChange={() =>
                                    onChangeCheckBoxHandler(
                                      item,
                                      defenceElements,
                                      setDefenceElements,
                                      element,
                                      elementsParent,
                                      setElementsParent,
                                      "coa",
                                      coa,
                                    )
                                  }
                                  style={{marginLeft: "12px"}}
                                />
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </Fragment>
                    ))
                  : null}
                <div className="d-flex justify-content-center">
                  <ButtonNew
                    tertiary
                    clickHandler={() =>
                      onClickAddCOAHandler(
                        coa === 0 ? {counterclaim: 0} : {counterclaim: 1}
                      )
                    }
                  >
                    Add{" "}
                    {coa === 0
                      ? obj__.new_case_type === "Criminal"
                        ? "Offence"
                        : "Cause of Action"
                      : "Counterclaim"}
                  </ButtonNew>
                </div>
              </div>
            </div>
          ))
          : null}

        {/* Themes*/}
        {activeMenuTab === 1 ? (
          <div className="form-group">
            <div className="container-fluid">
              {obj__.themes.map((theme, index) => (
                <div key={index} style={{marginBottom: "30px"}}>
                  <div style={{marginBottom: "8px"}}>
                    {/*<Bold14Font>{theme.name}</Bold14Font>*/}
                    <Checkbox
                      checked={themesParent.find((el) => el.id === theme.id)}
                      label={<Bold14Font>{theme.name}</Bold14Font>}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          theme,
                          themesParent,
                          setThemesParent
                        )
                      }
                    />
                  </div>
                  {theme.sub_themes.map((subTheme, idx) => (
                    <Checkbox
                      key={idx}
                      checked={themes.find(
                        (element) => element.id === subTheme.id
                      )}
                      label={subTheme.name}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          subTheme,
                          themes,
                          setThemes,
                          theme,
                          themesParent,
                          setThemesParent,
                          "themes"
                        )
                      }
                      style={{marginLeft: "12px"}}
                    />
                  ))}
                </div>
              ))}
              <div className="d-flex justify-content-center">
                <ButtonNew
                  tertiary
                  clickHandler={() => onClickAddThemeHandler(obj__)}
                >
                  Add Theme
                </ButtonNew>
              </div>
            </div>
          </div>
        ) : null}

        {/* Issues */}
        {activeMenuTab === 2 ? (
          <div className="form-group">
            <div className="container-fluid">
              {obj__.issues.map((issue, index) => (
                <div key={index} style={{marginBottom: "30px"}}>
                  <div style={{marginBottom: "8px"}}>
                    {/*<Bold14Font>{issue.name}</Bold14Font>*/}
                    <Checkbox
                      checked={issuesParent.find((el) => el.id === issue.id)}
                      label={<Bold14Font>{issue.name}</Bold14Font>}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          issue,
                          issuesParent,
                          setIssuesParent
                        )
                      }
                    />
                  </div>
                  {issue.key_facts.map((keyFact, idx) => (
                    <Checkbox
                      key={idx}
                      checked={issues.find(
                        (element) => element.id === keyFact.id
                      )}
                      label={keyFact.name}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          keyFact,
                          issues,
                          setIssues,
                          issue,
                          issuesParent,
                          setIssuesParent,
                          "issues"
                        )
                      }
                      style={{marginLeft: "12px"}}
                    />
                  ))}

                  {/*</div>*/}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <ButtonNew
                tertiary
                clickHandler={() => onClickAddIssueHandler(obj__)}
              >
                Add Issue
              </ButtonNew>
            </div>
          </div>
        ) : null}
      </div>

      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isLoading}
                wide
                primary
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AssociateElementsThemesIssuesForm.propTypes = {
  /**
   * Object to work with. TODO: Need to be renamed
   */
  evidenceObject: PropTypes.object.isRequired,
  /**
   * Function, that update object.
   */
  setEvidenceObject: PropTypes.func.isRequired,
  /**
   * When mount - select tab
   */
  selectedMenuTab: PropTypes.number,
  /**
   * ID of case
   */
  // caseId: PropTypes.number.isRequired,
  /**
   * ID of evidence
   */
  evidenceId: PropTypes.number.isRequired,
  /**
   * Function, that send data to server
   */
  sendData: PropTypes.func.isRequired,
};

export default AssociateElementsThemesIssuesForm;
