import React, { Fragment, useEffect, useState } from "react";
import { Bold14Font } from "../FontsNewComponent/Fonts";
import { TreeSelect } from "antd";
import { theme } from "../../styled-components/Theme/Theme";

const { TreeNode } = TreeSelect;

export default function FlagRelevanceFilter({
  size,
  caseObject,
  flagRelevance,
  onFlagRelevanceChange,
  style,
}) {
  const [issues, setIssues] = useState([]);
  const [themes, setThemes] = useState([]);
  const [coa, setCoa] = useState([]);

  useEffect(() => {
    setCoa(
      flagRelevance.filter(
        (v) =>
          v === "cause_of_action" ||
          v.indexOf("coa_") === 0 ||
          v.indexOf("defensive_") === 0 ||
          v.indexOf("element_") === 0
      )
    );
    setIssues(
      flagRelevance.filter(
        (v) =>
          v === "issues" ||
          v.indexOf("issue_") === 0 ||
          v.indexOf("key_fact_") === 0
      )
    );
    setThemes(
      flagRelevance.filter(
        (v) =>
          v === "themes" ||
          v.indexOf("theme_") === 0 ||
          v.indexOf("subtheme_") === 0
      )
    );
  }, flagRelevance);

  const onChange = (list, type) => {
    if (type === "issue") {
      setIssues(list);
      onFlagRelevanceChange([...list, ...themes, ...coa]);
    } else if (type === "coa") {
      setCoa(list);
      onFlagRelevanceChange([...issues, ...themes, ...list]);
    } else if (type === "theme") {
      setThemes(list);
      onFlagRelevanceChange([...issues, ...list, ...coa]);
    }
  };
  const CoaColor = (index) => {
    return index % 3 === 0 ? '#259b9a' : index % 2 === 0 ? '#25629b' : '#259b5e';
  };
  const IssueColor = (index) => {
    return index % 3 === 0 ? '#ff9017' : index % 2 === 0 ? '#ffc909' : '#ff1b17';
  };
  const ThemeColor = (index) => {
    return index % 3 === 0 ? '#7f48f4' : index % 2 === 0 ? '#d448f4' : '#4867f4';
  };
  return (
    <Fragment>
      <div className={`col-lg-${size ?? 2} mb-lg-0  col-12 mb-1`}>
        <TreeSelect
          showSearch={false}
          value={coa}
          style={style ? style : { width: "100%" }}
          virtual={false}
          dropdownStyle={{ maxHeight: 400, minWidth: 350, overflow: "auto" }}
          placeholder="Cause of action"
          allowClear
          multiple
          treeDefaultExpandAll
          onChange={(list) => {
            onChange(list, "coa");
          }}
        >
          {caseObject.causes_of_action && caseObject.causes_of_action.length ? (
            <TreeNode
              value="cause_of_action"
              title={
                <Bold14Font
                  as="div"
                  style={{
                    color: CoaColor(2),
                  }}
                >
                  {caseObject.new_case_type === 'Criminal' ? 'Offences' : 'Causes of Actions'}
                </Bold14Font>
              }
            >
              {caseObject.causes_of_action.map((item, index) => {
                return (
                  <TreeNode
                    key={`coa_${item.id}`}
                    value={`coa_${item.id}`}
                    title={
                      <Bold14Font
                        as="div"
                        style={{
                          color: CoaColor(index)
                        }}
                      >
                        {item.type.name}
                      </Bold14Font>
                    }
                  >
                    {item.elements.map((i) => {
                      return (
                        <TreeNode
                          key={`element_${i.id}`}
                          value={`element_${i.id}`}
                          title={
                            <Bold14Font
                              as="div"
                              style={{
                                color: CoaColor(index),
                              }}
                            >
                              {i.name}
                            </Bold14Font>
                          }
                        />
                      );
                    })}
                    {item.defence_elements.map((i) => {
                      return (
                        <TreeNode
                          key={`defensive_${i.id}`}
                          value={`defensive_${i.id}`}
                          title={
                            <Bold14Font
                              as="div"
                              style={{
                                color: CoaColor(index),
                              }}
                            >
                              {i.name}
                            </Bold14Font>
                          }
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
            </TreeNode>
          ) : null}
        </TreeSelect>
      </div>
      <div className={`col-lg-${size ?? 2} mb-lg-0  col-12 mb-1`}>
        <TreeSelect
          showSearch={false}
          value={issues}
          style={style ? style : { width: "100%" }}
          virtual={false}
          dropdownStyle={{ maxHeight: 400, minWidth: 350, overflow: "auto" }}
          placeholder="Issues"
          allowClear
          multiple
          treeDefaultExpandAll
          onChange={(list) => {
            onChange(list, "issue");
          }}
        >
          {caseObject.issues.length ? (
            <TreeNode
              value="issues"
              title={
                <Bold14Font
                  as="div"
                  style={{
                    color: IssueColor(2),
                  }}
                >
                  Issues
                </Bold14Font>
              }
            >
              {caseObject.issues.map((item, index) => {
                return (
                  <TreeNode
                    key={`issue_${item.id}`}
                    value={`issue_${item.id}`}
                    title={
                      <Bold14Font
                        as="div"
                        style={{
                          color: IssueColor(index),
                        }}
                      >
                        {item.name}
                      </Bold14Font>
                    }
                  >
                    {item.key_facts.map((i) => {
                      return (
                        <TreeNode
                          key={`key_fact_${i.id}`}
                          value={`key_fact_${i.id}`}
                          title={
                            <Bold14Font
                              as="div"
                              style={{
                                color: IssueColor(index),
                              }}
                            >
                              {i.name}
                            </Bold14Font>
                          }
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
            </TreeNode>
          ) : null}
        </TreeSelect>
      </div>
      <div className={`col-lg-${size ?? 2} mb-lg-0  col-12 mb-1`}>
        <TreeSelect
          showSearch={false}
          value={themes}
          virtual={false}
          style={style ? style : { width: "100%" }}
          dropdownStyle={{ maxHeight: 400, minWidth: 350, overflow: "auto" }}
          placeholder="Themes"
          allowClear
          multiple
          treeDefaultExpandAll
          onChange={(list) => {
            onChange(list, "theme");
          }}
        >
          {caseObject.themes.length ? (
            <TreeNode
              value="themes"
              title={
                <Bold14Font
                  as="div"
                  style={{
                    color: ThemeColor(2),
                  }}
                >
                  Themes
                </Bold14Font>
              }
            >
              {caseObject.themes.map((item, index) => {
                return (
                  <TreeNode
                    key={`theme_${item.id}`}
                    value={`theme_${item.id}`}
                    title={
                      <Bold14Font
                        as="div"
                        style={{
                          color: ThemeColor(index),
                        }}
                      >
                        {item.name}
                      </Bold14Font>
                    }
                  >
                    {item.sub_themes.map((i) => {
                      return (
                        <TreeNode
                          key={`subtheme_${i.id}`}
                          value={`subtheme_${i.id}`}
                          title={
                            <Bold14Font
                              as="div"
                              style={{
                                color: ThemeColor(index),
                              }}
                            >
                              {i.name}
                            </Bold14Font>
                          }
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
            </TreeNode>
          ) : null}
        </TreeSelect>
      </div>
    </Fragment>
  );
}
