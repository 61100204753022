import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateEvidence } from "../../../containers/Auth/auth";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { randomNumberRange } from "../../../utils";
import TextAreaSideBar from "../DocumentForms/TextAreaSideBar";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import {TagElementPurple} from "../../TagElementStyled/TagElementStyled";
import {HIDE_RIGHT_SIDE_BAR} from "../../../redux/types";

const EvidenceEditNote = ({ caseId, callback, evidence }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [notes, setNotes] = useState("");
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  const dispatch = useDispatch();
  let form = useRef(null);

  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setNotes(evidence.notes ?? "");
    }
  }, [evidence]);

  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ notes } };
    });
  }, [notes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      let resp;
      resp = await updateEvidence(evidence.id, caseId, dispatch, {
        notes,
      });
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      callback(resp);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <div className="mb-2">
            <div className="col">
              <Bold14Font>Witness</Bold14Font>
            </div>
          </div>
          <div className="col">
            {object.witnesses
              ? object.witnesses.map((v) => {
                  return <TagElementPurple key={v.id}>{v.name}</TagElementPurple>;
                })
              : null}
          </div>
        </div>
        <DividerHorizontal />
        <div className="form-group">
          <div className="mb-2">
            <div className="col">
              <Bold14Font>Note</Bold14Font>
            </div>
          </div>
          <TextAreaSideBar
            withMargin
            customPadding={'0.625rem 4.25rem 0.65rem 1.25rem'}
            placeholder={"Enter Note"}
            value={notes}
            name={"note"}
            form={`form-label-${randomNumber}`}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            id={`form-label-${randomNumber}`}
            ref={form}
          >
            <ButtonNew
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default EvidenceEditNote;
