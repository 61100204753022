import React, { useEffect, useState, Fragment } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route } from "./routes";

import LandingPage2 from "./containers/LandingPage2";
import SignUpPage, {
  signUpFlow,
} from "./containers/Auth/SignUpPage/SignUpPage";
import SignInPage from "./containers/Auth/SignInPage/SignInPage";
import SubscriptionStep2Page from "./containers/Subscription/SubscriptionStep2Page";
import SubscriptionStep3Page from "./containers/Subscription/SubscriptionStep3Page";
import ConfirmEmailPage from "./containers/Auth/ConfirmEmailPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Modal from "./components/Modal";
import StubPage from "./components/StubPage";
import BaseApp from "./containers/BaseApp";
import Logout from "./containers/Auth/Logout";
import { loadAccessToken, makeRequest } from "./containers/Auth/auth";
import {
  AUTO_LOG_IN,
  GET,
  HIDE_COOKIE_MESSAGE,
  SAVE_REQUESTED_URL,
} from "./redux/types";
import AdminSignIn from "./components/AdminSignInPage/AdminSignIn";
import AccessGranted from "./components/Access/AccessGranted";
import ForgotPasswordPage from "./containers/Auth/ForgotPasswordPage";
import EnterNewPasswordPage from "./containers/Auth/EnterNewPasswordPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SignUpGoogleAuthPage from "./containers/Auth/SignUpGoogleAuthPage";
import FullScreenBarLoader from "./components/FullScreenBarLoader";
import moment from "moment";
import StaticPage from "./containers/staticPages/StaticPage";
import {
  FLOW_COOKIES_POLICY,
  FLOW_PRIVACY_POLICY,
  FLOW_SECURITY_STATEMENT,
  FLOW_TERMS_AND_CONDITIONS,
} from "./containers/staticPages/StaticPage/StaticPage";

const stripePromise = loadStripe(process.env.MIX_STRIPE_KEY);
moment.locale("en");

function App() {
  const modal = useSelector((state) => state.modal);
  const id_admin = useSelector((state) => state.auth.user.is_admin);
  useEffect(() => {}, [modal.isModalShow]);
  const isUserAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const cookie_message = useSelector((state) => state.auth.cookie_message);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      dispatch({ type: SAVE_REQUESTED_URL, payload: window.location.pathname });
    }
    const handleAuth = async () => {
      if (!isUserAuth) {
        if (await loadAccessToken()) {
          try {
            const response = await makeRequest(
              GET,
              route.api.user.get,
              {},
              true
            );
            dispatch({ type: AUTO_LOG_IN, payload: response.data });
          } catch (e) {}
        }
      }
      // if (!isUserAuth) {
      //   if (await loadAccessToken()) {
      //     try {
      //       const response = await makeRequest(
      //         GET,
      //         route.api.user.get,
      //         {},
      //         true
      //       );
      //       dispatch({ type: AUTO_LOG_IN, payload: response.data });
      //       if (!response.data.is_admin) {
      //         if (response.data["email_verified_at"] === null) {
      //           history.push(route.auth.confirmEmail);
      //         } else if (!response.data["role"] && !response.data["country"]) {
      //           history.push(route.auth.signUpWithGoogle);
      //         } else if (
      //           response.data["email_verified_at"] &&
      //           (!response.data.features || !response.data.features.length)
      //         ) {
      //           history.push(route.subscription.step2);
      //         } else {
      //           // history.push(route.baseApp.app);
      //         }
      //       }
      //     } catch (e) {
      //       console.log(e);
      //     }
      //   }
      // }
      // await redirectUserToAppropriatePage(isUserAuth, history, dispatch);
      setIsLoading(false);
    };
    handleAuth();
  }, []);

  if (isLoading) {
    return <FullScreenBarLoader />;
  }

  return (
    <Elements stripe={stripePromise}>
      {/*<TryAutoLogin />*/}
      {modal.isModalShow ? (
        <Modal
          type={modal.type}
          header={modal.header}
          description={modal.data}
        />
      ) : null}
      <Switch>
        <Route exact path={route.index} component={LandingPage2} />
        <Route exact path={route.termsAndConditions}>
          {/*<TermsAndConditions />*/}
          <StaticPage flow={FLOW_TERMS_AND_CONDITIONS} />
        </Route>
        <Route exact path={route.privacyPolicy}>
          {/*<PrivacyPolicy />*/}
          <StaticPage flow={FLOW_PRIVACY_POLICY} />
        </Route>
        <Route exact path={route.securityStatement}>
          {/*<SecurityStatement />*/}
          <StaticPage flow={FLOW_SECURITY_STATEMENT} />
        </Route>
        <Route exact path={route.cookiesPolicy}>
          {/*<Cookies />*/}
          <StaticPage flow={FLOW_COOKIES_POLICY} />
        </Route>
        <Route exact path={route.auth.signUp} component={SignUpPage} />
        <Route
          exact={true}
          path="/access/:id_case([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})?/:token([a-z0-9]{32})?"
          component={AccessGranted}
        />
        <Route path={route.auth.signIn} component={SignInPage} />
        <Route
          path={route.auth.restorePassword}
          component={ForgotPasswordPage}
        />
        <Route
          exact={true}
          path={route.admin.auth.signIn}
          component={AdminSignIn}
        />
        <Route path={route.auth.logOut} component={Logout} />
        <PrivateRoute exact={true} path={route.auth.confirmEmail}>
          <ConfirmEmailPage />
        </PrivateRoute>
        <PrivateRoute exact={true} path={route.auth.signUpWithGoogle}>
          <SignUpGoogleAuthPage />
        </PrivateRoute>
        {isUserAuth ? (
          <Route exact path={route.auth.enterNewPassword}>
            <EnterNewPasswordPage />
          </Route>
        ) : null}
        <Route exact path={route.invite}>
          <SignUpPage flow={signUpFlow.INVITE_BY_TOKEN} />
        </Route>
        {user.role !== 'assistant' ?
          <PrivateRoute exact={true} path={route.subscription.step2}>
            <SubscriptionStep2Page />
          </PrivateRoute>
          : null}
        {user.role !== 'assistant' ?
        <PrivateRoute exact={true} path={route.subscription.step3}>
          <SubscriptionStep3Page />
        </PrivateRoute>
        : null}
        {id_admin ? (
          <PrivateRoute path={route.admin.baseApp.app}>
            <BaseApp />
          </PrivateRoute>
        ) : (
          <PrivateRoute path={route.baseApp.app}>
            <BaseApp />
          </PrivateRoute>
        )}
        {/* 404 route */}
        <Route exact={true} path="/admin">
          <Redirect to={route.admin.auth.signIn} />
        </Route>
        <Route path="*">
          <StubPage>404 Page Not Found</StubPage>
        </Route>
      </Switch>
      {cookie_message && !id_admin ? (
        <div
          className="alert text-center cookiealert show d-flex justify-content-center align-items-center"
          role="alert"
        >
          <div className="p-3">
            TrialProofer uses cookies for strictly necessary purposes, to allow
            Users to login and stay authenticated within the web application. By
            continuing to use this website and signing in to the TrialProofer
            WebApp you consent to the use of cookies in accordance with our{" "}
            <Link to={"/cookies-policy"}>Cookie Policy.</Link>.&nbsp;
            TrialProofer permits Stripe Cookies for necessary payment purposes
            but does not use any third-party cookies for analytics or
            advertising purposes. If we ever change, we will first request your
            consent.
          </div>
          <div className="p-3 pl-0">
            <button
              onClick={() => {
                dispatch({ type: HIDE_COOKIE_MESSAGE });
              }}
              type="button"
              className="btn btn-primary btn-sm acceptcookies"
            >
              Agree
            </button>
          </div>
        </div>
      ) : null}
    </Elements>
  );
}

export default App;
