import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import ListAddButton from "../../buttons/ListAddButton";
import Label from "../AddCardForm/Label";
import { theme } from "../../../styled-components/Theme/Theme";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableString from "./EditableString";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { addIssues, updateIssues } from "../../../containers/Auth/auth";
import EditableStringClassComponent from "./EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import InputNew from "../../InputNew/InputNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

export default function IssuesForm(prop) {
  let { object, id_case } = prop;
  const dispatch = useDispatch();
  const [object_, setObject] = useState(object);
  const [name, setName] = useState(object.name ?? "");
  const [isLoading, setIsLoading] = useState(false);
  let form = useRef(null);
  let editableStringsRef = useRef([]);

  const addKeyFact = async (e) => {
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      let key_facts;
      if (!object_.key_facts) {
        key_facts = [{ name: "" }];
      } else {
        key_facts = [...object_.key_facts, { name: "" }];
      }
      setObject({
        ...object_,
        ...{ key_facts },
      });
    }
  };

  const deleteIssue = (index) => {
    setObject({
      ...object_,
      ...{
        key_facts: object_.key_facts.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };

  const editIssue = (index, name) => {
    let key_facts = object_.key_facts;
    key_facts[index].name = name;
    setObject({
      ...object_,
      ...{ key_facts },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      let resp;
      let data = {
        name: name,
        key_facts: object_.key_facts
          ? object_.key_facts.filter((el) => el.name !== "")
          : [],
      };
      if (prop.sendRequest) {
        if (object.id) {
          resp = await updateIssues(id_case, object.id, dispatch, data);
        } else {
          resp = await addIssues(id_case, dispatch, data);
        }
      } else {
        resp = {
          id: object.id ?? `new_${Math.random()}`,
          ...data
        }
      }
      setIsLoading(false);
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update"
        );
      }
    }
  };

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  return (
    <Fragment>
      <div className="form-group">
        <InputNew
          maxLength={255}
          type={"text"}
          required
          label={"Issue"}
          onChangeHandler={(e) => {
            setName(e.target.value);
          }}
          autoFocus
          value={name}
          placeholder={"Enter issue name"}
          name={"name"}
          form={`form-issue-${object.id}`}
        />
      </div>

      <div style={{ height: "100%", overflowY: "auto" }}>
        {object_.key_facts && object_.key_facts.length ? (
          <Fragment>
            <AnimatedDiv className="form-group mb-0">
              <Label className={"row"} name={"Key Facts"} />
            </AnimatedDiv>
            {object_.key_facts.map((sub_t, i) => {
              {
                return (
                  <AnimatedDiv key={sub_t.id ?? i} className="form-group pl-3">
                    <EditableStringClassComponent
                      editName={editIssue}
                      editState={typeof sub_t.id === "undefined"}
                      deleteAction={deleteIssue}
                      object={sub_t}
                      index={i}
                      ref={(ref) => assignRef(ref, i)}
                    />
                  </AnimatedDiv>
                );
              }
            })}
          </Fragment>
        ) : null}
        <div className="form-group">
          <div className="container-fluid">
            <ListAddButton
              className="d-flex"
              label="Add Key Fact"
              clickHandler={addKeyFact}
            />
          </div>
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            ref={form}
            id={`form-issue-${object.id}`}
            onSubmit={handleSubmit}
          >
            <ButtonNew
              type="submit"
              // clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
IssuesForm.propTypes = {
  object: PropTypes.object,
  id_case: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};
