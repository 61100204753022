import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers/rootReducer";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import Theme from "./styled-components/Theme";
import GlobalStyle from "./styled-components/GlobalStyle";
import App from "./App";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? // ? window.__REDUX_DEVTOOLS_EXTENSION__
    window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true, trace: true })
  : (a) => a;

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), devTools)
);

export const history = createBrowserHistory();

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <Theme>
        <Router history={history}>
          <GlobalStyle />
          <App />
        </Router>
      </Theme>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("app"));
