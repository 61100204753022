import axios from "axios";
import { route } from "../../routes";
import { DATA_ADD_TO_STORE, DELETE, GET, POST, PUT } from "../../redux/types";
import modalErrorHandler from "../../components/Modal/modalErrorHandler";
import {
  FLOW_COOKIES_POLICY,
  FLOW_PRIVACY_POLICY,
  FLOW_SECURITY_STATEMENT,
  FLOW_TERMS_AND_CONDITIONS,
} from "../staticPages/StaticPage/StaticPage";
import { checkIfUserHaveSubscription, checkRestrictCaseError } from "./utils";
import { history } from "../../index";

const checkLocalStorage = async () => {
  const data = "data";
  try {
    await localStorage.setItem(data, data);
    await localStorage.removeItem(data);
    return true;
  } catch (e) {
    return false;
  }
};

// Token functionality
/**
 * Try save access token to local storage. If storage not accessible - save to cookie.
 */
const saveAccessToken = async (accessToken) => {
  // Check if local storage is accessible.
  if (await checkLocalStorage()) {
    await localStorage.setItem("accessToken", accessToken);
  }
  // TODO: Implement cookie
};

/**
 * Try load access token first from local storage; then from cookie.
 * @return string: access token
 */
export const loadAccessToken = async () => {
  if (await checkLocalStorage()) {
    const item = await localStorage.getItem("accessToken");
    return item;
  }
  // TODO: Implement cookie
};

/**
 * Check if user have access token in local storage
 */

// eslint-disable-next-line no-unused-vars
const checkAccessTokenInLocalStorage = async () => {
  if (await checkLocalStorage()) {
  }
};

/**
 * Delete access token
 * @return boolean
 */
const deleteAccessToken = async () => {
  if (await checkLocalStorage()) {
    localStorage.removeItem("accessToken");
    return true;
  }
};

// API access:
export const makeRequest = async (
  method,
  route,
  body = {},
  auth = false,
  blob = false
) => {
  let config = {};
  if (auth) {
    config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
  }
  if (blob) {
    config.responseType = "blob";
  }
  switch (method) {
    case GET:
      return await axios.get(route, config);

    case POST:
      return await axios.post(route, body, config);

    case PUT:
      return await axios.put(route, body, config);

    case DELETE:
      return await axios.delete(route, config);

    default:
      new Error("ERROR");
  }
};

// User manipulation
/**
 * Try sign up user.
 * @param userObject: Object
 * @return object: response.data
 */
export const signUp = async (userObject) => {
  try {
    const response = await makeRequest(POST, route.api.auth.signUp, userObject);
    if (response.status === 200) {
      const accessToken = response.data.token;
      await saveAccessToken(accessToken);
      return response;
    } else {
      return response;
    }
  } catch (e) {
    return e;
  }
};

/**
 * Re-send email with code
 */
export const resendVerifyEmail = async () => {
  const response = makeRequest(
    POST,
    route.api.auth.resendVerificationCode,
    {},
    true
  );
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${await loadAccessToken()}`,
  //   },
  // };
  // const response = await axios.post(
  //   "/api/auth/resend-verification-code",
  //   {},
  //   config
  // );
  if (response.status === 200) {
    return response;
  }
};
/**
 * Re-send sms with code
 */
export const resendVerifySms = async (dispatch, data) => {
  try {
    const response = await makeRequest(
      POST,
      data
        ? route.api.user.resendVerificationCodeSMSNoAuth
        : route.api.user.resendVerificationCodeSMS,
      data ?? {},
      !data
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return false;
};
/**
 * Verify
 * @param codeObject: Object
 * @return {Promise<AxiosResponse<any>>}
 */
export const verifyEmail = async (codeObject) => {
  return await makeRequest(
    POST,
    route.api.auth.emailVerification,
    codeObject,
    true
  );
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${await loadAccessToken()}`,
  //   },
  // };
  // const response = await axios.post(
  //   "/api/auth/email-verification",
  //   codeObject,
  //   config
  // );
  // // TODO: re-implement logic
  // if (response.status === 200) {
  //   return response;
  // } else {
  //   // TODO: implement errors handler
  //   console.log(response);
  // }
};

/**
 * Sign In user
 * @param credentials
 * @return {Promise<AxiosResponse<any>>}
 */
export const signIn = async (credentials) => {
  const response = await axios.post("/api/auth/sign-in", credentials);
  if (response.status === 200) {
    await saveAccessToken(response.data.token);
    return response;
  } else {
    // TODO: implement errors handler
    return response;
  }
};

export const restorePassword = async (email) => {
  const body = {
    email: email,
  };
  const response = await makeRequest(POST, route.api.auth.forgotPassword, body);
  if (response.status === 200) {
    return response;
  } else {
    return response;
  }
};

export const verifyEmailOnPasswordReset = async (email, code) => {
  const body = {
    ...code,
    email: email,
  };
  const response = await makeRequest(POST, route.api.auth.resetPassword, body);
  if (response.status === 200) {
    const token = response.data.token;
    await saveAccessToken(token);
    return response;
  } else {
    return response;
  }
};

export const updatePassword = async (passwordsObject) => {
  const body = {
    ...passwordsObject,
  };

  const response = await makeRequest(
    POST,
    route.api.auth.updatePassword,
    body,
    true
  );
  await deleteAccessToken();
  return response;

  // if (response.status === 200) {
  //   return response;
  // } else {
  //   return response;
  // }
};

export const signInAdmin = async (credentials) => {
  const response = await axios.post("/api/admin/sign-in", credentials);
  if (response.status === 200) {
    await saveAccessToken(response.data.token);
    return response;
  } else {
    return response;
  }
};

export const getCases = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.cases.get, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const subNews = async (email, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.subToNews, { email }, false);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const contact_us = async (form, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.contactUs, form, false);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const getAdminCases = async (id_user, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `${route.api.admin.cases}${id_user ? "?id_user=" + id_user : ""}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const getCasesTotal = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.cases.total, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const getAdminCasesTotal = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.casesTotal, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const getAdminCasesTypes = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.case_types, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const getAdminCausesOfActions = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.causes_of_actions, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const getAdminOffences= async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.causes_of_actions+'?is_criminal=1', {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const getAdminReliefs = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.reliefs, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const getAdminTestimonials = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.testimony, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const getTestimonials = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, "/api/testimonial", {}, false);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const updateCase = async (id, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(PUT, route.api.cases.get + "/" + id, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

/**
 * Create new case if user have subscription. Else - redirect user to subscription page
 * @param dispatch
 * @param data
 * @param user
 * @param history
 * @returns {Promise<*>}
 */
export const addCase = async (dispatch, data, user, history) => {
  if (checkIfUserHaveSubscription(user, history, dispatch)) {
    let resp;
    try {
      resp = await makeRequest(POST, route.api.cases.get, data, true);
    } catch (e) {
      modalErrorHandler(e, dispatch);
    }
    return resp.data.data;
  }
};
export const getBundles = async (dispatch, id_case) => {
  let resp;
  try {
    resp = await makeRequest(GET, "/api/bundle?id_case=" + id_case, null, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const addBundle = async (dispatch, data, afterErrorHandler) => {
  let resp;
  try {
    resp = await makeRequest(POST, "/api/bundle", data, true);
  } catch (e) {
    if (afterErrorHandler) {
      afterErrorHandler();
    }
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const updateBundle = async (
  bundleId,
  dispatch,
  data,
  afterErrorHandler
) => {
  let resp;
  try {
    resp = await makeRequest(PUT, `/api/bundle/${bundleId}`, data, true);
  } catch (e) {
    if (afterErrorHandler) {
      afterErrorHandler();
    }
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteBundle = async (
  bundleId,
  dispatch,
  data = {},
  afterErrorHandler
) => {
  let resp;
  try {
    resp = await makeRequest(DELETE, `/api/bundle/${bundleId}`, data, true);
  } catch (e) {
    if (afterErrorHandler) {
      afterErrorHandler();
    }
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

export const getCaseById = async (
  id,
  dispatch,
  withDocuments,
  withApplications
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      route.api.cases.get +
        "/" +
        id +
        `${withDocuments ? "?with_documents=true" : ""}` +
        `${withApplications ? "?with_applications=true" : ""}`,
      {},
      true
    );
    dispatch({
      type: DATA_ADD_TO_STORE,
      keyName: "case",
      data: resp.data.data,
    });
  } catch (e) {
    checkRestrictCaseError(e, id, dispatch);
  }
  if (!resp) {
    return null;
  }
  return resp.data.data;
};

export const getCaseLog = async (id, page, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      route.api.cases.get + "/" + id + "/log?page=" + page,
      {},
      true
    );
  } catch (e) {
    checkRestrictCaseError(e, id, dispatch);
  }
  if (!resp) {
    return null;
  }
  return resp.data;
};

export const getDashboardData = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.dashboard, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  if (!resp) {
    return null;
  }
  return resp.data;
};
export const getAdminDashboardData = async (dispatch, data) => {
  let resp;
  let url = route.api.admin.dashboard;
  if (data) {
    url +=
      "?" +
      Object.keys(data)
        .map((v) => {
          return `${v}=${data[v]}`;
        })
        .join("&");
  }
  try {
    resp = await makeRequest(GET, url, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  if (!resp) {
    return null;
  }
  return resp.data;
};
export const getAdminCaseById = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `${route.api.admin.cases}/${id}`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  if (!resp) {
    return null;
  }
  return resp.data.data;
};

export const deleteCase = async (id, dispatch) => {
  if (checkIfUserHaveSubscription()) {
    let resp;
    try {
      resp = await makeRequest(
        DELETE,
        route.api.cases.get + "/" + id,
        {},
        true
      );
    } catch (e) {
      modalErrorHandler(e, dispatch);
    }
    return resp.data.data;
  }
};
export const deleteCard = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(DELETE, "/api/subscriptions/card", {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data ? resp.data : false;
};
export const addCard = async (data, dispatch, disableModal = false) => {
  let resp;
  try {
    resp = await makeRequest(POST, "/api/subscriptions/card", data, true);
  } catch (e) {
    if (!disableModal) {
      modalErrorHandler(e, dispatch);
    }
    return e;
  }
  return resp && resp.data ? resp.data : false;
};

export const toggleArchiveCaseStatus = async (id, archivedStatus, dispatch) => {
  let resp;
  const body = { archived: !archivedStatus };
  try {
    resp = await makeRequest(PUT, `${route.api.cases.get}/${id}`, body, true);
  } catch (e) {
    checkRestrictCaseError(e, id, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteCaseType = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.admin.case_types + "/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const addCasesTypes = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.admin.case_types, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const updateCasesTypes = async (id, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `${route.api.admin.case_types}/${id}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const deleteCOA = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.admin.causes_of_actions + "/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const deleteRelief = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.admin.reliefs + "/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const deleteTestimony = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.admin.testimony + "/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const updateCOA = async (id, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.admin.causes_of_actions + "/" + id,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const updateRelief = async (id, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.admin.reliefs + "/" + id,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const updateTestimonial = async (id, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.admin.testimony + "/" + id,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const createCOA = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.admin.causes_of_actions,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const createRelief = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.admin.reliefs, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};
export const createTestimonial = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.admin.testimony, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

export const getCasesTypes = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.cases.types, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp.data.data;
};

/**
 * Logout user. Delete access token from all places and remove user data from global storage.
 */
export const logOut = () => {
  return deleteAccessToken();
};

export const getSubscriptions = async () => {
  let response = await makeRequest(GET, route.api.subscriptions.get);
  return response.data;
};

export const getSubscriptionsAuthorized = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      route.api.subscriptions.getAuthorized,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }

  return resp && resp.data ? resp.data : false;
};

export const addSubscription = async (codeObject) => {
  return await makeRequest(
    POST,
    route.api.subscriptions.addToUser,
    codeObject,
    true
  );
};
export const createStripeIntent = async (codeObject) => {
  return await makeRequest(
    POST,
    route.api.subscriptions.createStripeIntent,
    codeObject,
    true
  );
};

export const addParties = async (id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.cases.get + "/" + id + "/parties",
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateParties = async (id_case, id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.cases.get + "/" + id_case + "/parties/" + id,
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteParties = async (id_case, id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.cases.get + "/" + id_case + "/parties/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const addThemes = async (id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.cases.get + "/" + id + "/themes",
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateThemes = async (id_case, id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.cases.get + "/" + id_case + "/themes/" + id,
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteThemes = async (id_case, id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.cases.get + "/" + id_case + "/themes/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const addIssues = async (id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.cases.get + "/" + id + "/issues",
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateIssues = async (id_case, id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.cases.get + "/" + id_case + "/issues/" + id,
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteIssues = async (id_case, id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.cases.get + "/" + id_case + "/issues/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const addCauseOfAction = async (id, dispatch, codeObject) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      route.api.cases.get + "/" + id + "/cause-of-action",
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateCauseOfAction = async (
  id_case,
  id,
  dispatch,
  codeObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      route.api.cases.get + "/" + id_case + "/cause-of-action/" + id,
      codeObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const deleteCauseOfAction = async (id_case, id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      route.api.cases.get + "/" + id_case + "/cause-of-action/" + id,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const getCauseOfActions = async (id_case, dispatch, criminal) => {
  let resp;
  try {
    resp = await makeRequest(GET,  `${route.api.cases.get}/elements${criminal ? '?is_criminal=1' : ''}`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const createCustomCOA = async (id_case, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${id_case}/cause-of-action/custom`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const requireAccessToCase = async (id_case, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.admin.cases}/${id_case}/get-access`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const getAdminUsers = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, route.api.admin.users, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const addAdminUser = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, route.api.admin.users, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const updateAdminUser = async (id, data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(PUT, `${route.api.admin.users}/${id}`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const updateProfile = async (data, dispatch) => {
  let resp;
  if (data.phone === null) {
    data.phone = "";
  }
  try {
    resp = await makeRequest(PUT, `/api/user`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data ? resp.data : false;
};
export const updateImageAdminUser = async (id, data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.admin.users}/${id}/photo`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const updateUserImage = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/user/photo`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const deleteAdminUser = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `${route.api.admin.users}/${id}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const getAdminUserById = async (id, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `${route.api.admin.users}/${id}`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const blockUnblockUser = async (id, data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.admin.users}/${id}/block`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const updateUserPassword = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/auth/update-password`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const setUser2FA = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/user/set-2-fa`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const getActiveSubscription = async (dispatch, last) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/subscriptions/active${last ? "?last=true" : ""}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data : false;
};
export const cancelUserSubscription = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/subscriptions/cancel`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data ? resp.data : false;
};
export const swapSubscription = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(PUT, `${route.api.subscriptions.get}`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

export const signUpWithGoogle = async (token, dispatch, data) => {
  try {
    let body = {
      token,
    };
    if (data) {
      body = { ...body, ...data };
    }
    const response = await makeRequest(
      POST,
      route.api.auth.signUpWithGoogle,
      body
    );
    if (response.status === 200) {
      const accessToken = response.data.token;
      await saveAccessToken(accessToken);
      return response;
    } else {
      return response;
    }
  } catch (e) {
    return e;
  }
};

export const adminGetUserActiveSubscription = async (userId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      route.api.admin.subscriptions.getUserSubscription(userId),
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

export const adminSetSubscriptionToUser = async (userId, requestBody) => {
  return await makeRequest(
    POST,
    route.api.admin.subscriptions.addSubscriptionToUser(userId),
    requestBody,
    true
  );
};

/**
 * Get list of evidence
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getEvidenceList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/evidence`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of documents
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getDocumentsList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/documents`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get document
 * @param caseId
 * @param documentId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getDocument = async (caseId, documentId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/documents/${documentId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Delete document
 * @param caseId
 * @param documentId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deleteDocument = async (caseId, documentId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/documents/${documentId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Get document content
 * @param caseId
 * @param documentId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getDocumentContent = async (caseId, documentId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/documents/${documentId}/content`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp;
};

/**
 * Create new evidence
 * @param caseId
 * @param dispatch
 * @param object
 * @returns {Promise<*>}
 */
export const createNewEvidence = async (caseId, dispatch, object = null) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/evidence`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Create new sub_evidence
 * @param caseId
 * @param dispatch
 * @param id_document
 * @param object
 * @returns {Promise<*>}
 */
export const createNewSubEvidence = async (
  caseId,
  dispatch,
  id_document,
  object = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/documents/${id_document}/key-section`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const updateSubEvidence = async (
  evidenceId,
  caseId,
  id_document,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/documents/${id_document}/key-section/${evidenceId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const deleteSubEvidenceApi = async (
  caseId,
  dispatch,
  id_document,
  id
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/documents/${id_document}/key-section/${id}`,
      null,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Get evidence by id
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getEvidenceById = async (evidenceId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/evidence/${evidenceId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete evidence by id
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deleteEvidence = async (evidenceId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/evidence/${evidenceId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update evidence
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateEvidence = async (
  evidenceId,
  caseId,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/evidence/${evidenceId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update evidence
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateEvidenceCoreBook = async (
  caseId,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/evidence/core_book`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Linking evidence flag relevance with added witnesses
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<void>}
 */
export const copyRelevanceFromEvidenceToWitnessesList = async (
  evidenceId,
  caseId,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/evidence/${evidenceId}/link-relevance`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  // return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateObjective = async (id, dispatch, submitObject) => {
  let resp;
  try {
    resp = await makeRequest(PUT, `/api/objectives/${id}`, submitObject, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Upload file to evidence
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const uploadEvidenceFile = async (
  evidenceId,
  caseId,
  dispatch,
  data
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/evidence/${evidenceId}/file`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const saveDocumentMarkups = async (
  documentId,
  caseId,
  dispatch,
  data
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/documents/${documentId}/markups`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get evidence file preview image
 * @param evidenceId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const previewEvidenceFile = async (evidenceId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/evidence/${evidenceId}/preview`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Bulk import of evidences docs
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const bulkImportEvidence = async (caseId, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/evidence/import`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 *
 * Bulk import of evidences docs with linked witness
 * @param caseId
 * @param witnessId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const bulkImportEvidenceWithWitness = async (
  caseId,
  witnessId,
  dispatch,
  data
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/witness/${witnessId}/import-evidence`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateDocument = async (documentId, caseId, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/documents/${documentId}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const uploadDocumentFile = async (
  documentId,
  caseId,
  dispatch,
  data
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/documents/${documentId}/file`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const addDocumentCall = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/documents`,
      null,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of witnesses
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getWitnessList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/witness`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new Witness
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const createNewWitness = async (caseId, dispatch, data) => {
  let resp;
  if (typeof data === "undefined") {
    data = {};
  }
  try {
    resp = await makeRequest(POST, `/api/cases/${caseId}/witness`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get witness by ID
 * @param caseId
 * @param witnessId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getWitnessById = async (caseId, witnessId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/witness/${witnessId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const getWitnessProofsThemes = async (
  caseId,
  witnessId,
  dispatch,
  exam
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/witness-proofs/${witnessId}/themes${
        exam ? "?exam=true" : ""
      }`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const generateDocWitness = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/docs/witness`, data, true, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const generateDocCoreBook = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/docs/core-book`, data, true, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const generateDocChronology = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/docs/chronology`, data, true, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const generateDocChecklist = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/docs/checklist`, data, true, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const generateDocAuthorities = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/docs/authorities`, data, true, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const generateDocAdviceOnProofs = async (dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/docs/advice-on-proofs`,
      data,
      true,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
export const getWitnessProofsIssues = async (
  caseId,
  witnessId,
  dispatch,
  exam
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/witness-proofs/${witnessId}/issues${
        exam ? "?exam=true" : ""
      }`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const getWitnessProofsElements = async (
  caseId,
  witnessId,
  dispatch,
  exam
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/witness-proofs/${witnessId}/elements${
        exam ? "?exam=true" : ""
      }`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Delete witness by ID
 * @param caseId
 * @param witnessId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deleteWitnessById = async (caseId, witnessId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/witness/${witnessId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

/**
 * Update witness by ID
 * @param caseId
 * @param witnessId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateWitnessById = async (
  caseId,
  witnessId,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/witness/${witnessId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};



/**
 * Get X-Exam witness by ID
 * @param caseId
 * @param xExamWitnessId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getXExamWitnessById = async (caseId, xExamWitnessId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/ex-witness/${xExamWitnessId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};



/**
 * Add new topic
 * @param caseId
 * @param xExamWitnessId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const addNewTopic = async (
  caseId,
  xExamWitnessId,
  dispatch,
  submitObject
) => {
  let resp;
  submitObject.witness_id = xExamWitnessId ?? null;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/topics`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update topic
 * @param caseId
 * @param xExamWitnessId
 * @param topicId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateTopicById = async (
  caseId,
  xExamWitnessId,
  topicId,
  dispatch,
  submitObject
) => {
  let resp;
  submitObject.witness_id = xExamWitnessId ?? null;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/topics/${topicId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete topic
 * @param caseId
 * @param xExamWitnessId
 * @param topicId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const deleteTopicById = async (
  caseId,
  xExamWitnessId,
  topicId,
  dispatch
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/topics/${topicId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

/**
 *
 * Bulk import of evidences docs with linked topic
 * @param caseId
 * @param xExamId
 * @param topicId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const bulkImportEvidenceWithTopic = async (
  caseId,
  xExamId,
  topicId,
  dispatch,
  data
) => {
  let resp;
  if (xExamId) {
    data.append('witness_id', xExamId)
  }
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/topics/${topicId}/import-evidence`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of authorities
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getAuthoritiesList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/authority`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of ALL authorities
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getAllAuthoritiesList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/authority?all=true`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new authority
 * @param caseId
 * @param dispatch
 * @param object
 * @returns {Promise<*>}
 */
export const createNewAuthority = async (caseId, dispatch, object = null) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/authority`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get authority by id
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getAuthorityById = async (authorityId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/authority/${authorityId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update authority by id
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateAuthorityById = async (
  authorityId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/authority/${authorityId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete authority by id
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const deleteAuthorityById = async (
  authorityId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/authority/${authorityId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

/**
 * Get authority file
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getAuthorityFileById = async (authorityId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/authority/${authorityId}/preview`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Add file to authority
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const uploadAuthorityFile = async (
  authorityId,
  caseId,
  dispatch,
  data
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/authority/${authorityId}/file`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Authorities bulk import from files
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const authoritiesBulkImportRequest = async (caseId, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/authority/import`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Authorities bulk import from files
 * @param caseId
 * @param dispatch
 * @param data
 * @returns {Promise<*>}
 */
export const casesBulkImportRequest = async (caseId, dispatch, data) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/cases/${caseId}/import`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get authority file preview image
 * @param authorityId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const previewAuthorityFile = async (authorityId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/authority/${authorityId}/preview`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of applications
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getApplicationsList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/applications`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new application
 * @param caseId
 * @param dispatch
 * @param object
 * @returns {Promise<*>}
 */
export const createNewApplication = async (caseId, dispatch, object = null) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/applications`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get application by id
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getApplicationById = async (applicationId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/applications/${applicationId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update application by id
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateApplicationById = async (
  applicationId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/applications/${applicationId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete application by id
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const deleteApplicationById = async (
  applicationId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/applications/${applicationId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

/**
 * Create new relief
 * @param caseId
 * @param dispatch
 * @param object
 * @returns {Promise<*>}
 */
export const createNewRelief = async (caseId, dispatch, object = null) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/reliefs`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of reliefs
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getReliefsList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/reliefs`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update relief by id
 * @param reliefId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateReliefById = async (
  reliefId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/reliefs/${reliefId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete relief by id
 * @param reliefId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const deleteReliefById = async (
  reliefId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/reliefs/${reliefId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

/**
 * Get list of application papers
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getApplicationPapersList = async (
  applicationId,
  caseId,
  dispatch
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/applications/${applicationId}/papers`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new application paper
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param object
 * @returns {Promise<*>}
 */
export const createNewApplicationPaper = async (
  applicationId,
  caseId,
  dispatch,
  object = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/applications/${applicationId}/papers`,
      object ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get application paper by id
 * @param applicationPaperId
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getApplicationPaperById = async (
  applicationPaperId,
  applicationId,
  caseId,
  dispatch
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/applications/${applicationId}/papers/${applicationPaperId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update application paper by id
 * @param applicationPaperId
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateApplicationPaperById = async (
  applicationPaperId,
  applicationId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/applications/${applicationId}/papers/${applicationPaperId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete application paper by id
 * @param applicationPaperId
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const deleteApplicationPaperById = async (
  applicationPaperId,
  applicationId,
  caseId,
  dispatch,
  submitObject = null
) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/applications/${applicationId}/papers/${applicationPaperId}`,
      submitObject ?? {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

/**
 * Get application paper file preview by id
 * @param applicationPaperId
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const previewApplicationPaperFileById = async (
  applicationPaperId,
  applicationId,
  caseId,
  dispatch
) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `/api/cases/${caseId}/applications/${applicationId}/papers/${applicationPaperId}/preview`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Set application paper files by id
 * @param applicationPaperId
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param formData
 * @returns {Promise<*>}
 */
export const createApplicationPaperFileById = async (
  applicationPaperId,
  applicationId,
  caseId,
  dispatch,
  formData
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/applications/${applicationId}/papers/${applicationPaperId}/file`,
      formData,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Bulk Import application paper files
 * @param applicationId
 * @param caseId
 * @param dispatch
 * @param formData
 * @returns {Promise<*>}
 */
export const bulkImportApplicationPaperFiles = async (
  applicationId,
  caseId,
  dispatch,
  formData
) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/applications/${applicationId}/papers/import`,
      formData,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of events
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getEventsList = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/cases/${caseId}/event`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new event
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const createNewEvent = async (caseId, dispatch, submitObject) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `/api/cases/${caseId}/event`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update event
 * @param eventId
 * @param caseId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updateEventById = async (
  eventId,
  caseId,
  dispatch,
  submitObject
) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/cases/${caseId}/event/${eventId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete event
 * @param eventId
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deleteEventById = async (eventId, caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `/api/cases/${caseId}/event/${eventId}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

/**
 * Get pages or tutorials list
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getPagesList = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `/api/help`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get pages or tutorials list for unauthenticated user
 * @param flow
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getPagesUnAuthList = async (flow, dispatch) => {
  let resp;
  let pageId;
  switch (flow) {
    case FLOW_PRIVACY_POLICY:
      pageId = 2;
      break;
    case FLOW_TERMS_AND_CONDITIONS:
      pageId = 1;
      break;
    case FLOW_SECURITY_STATEMENT:
      pageId = 3;
      break;
    case FLOW_COOKIES_POLICY:
      pageId = 4;
      break;
    default:
      return;
  }
  try {
    resp = await makeRequest(
      GET,
      `/api/help-front${pageId ? `/${pageId}` : null}`,
      {},
      false
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Create new page or tutorial
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const createNewPage = async (dispatch, submitObject) => {
  let resp;
  try {
    resp = await makeRequest(POST, `/api/admin/help`, submitObject, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Update page or tutorial
 * @param pageId
 * @param dispatch
 * @param submitObject
 * @returns {Promise<*>}
 */
export const updatePageById = async (pageId, dispatch, submitObject) => {
  let resp;
  try {
    resp = await makeRequest(
      PUT,
      `/api/admin/help/${pageId}`,
      submitObject,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete page or tutorial
 * @param pageId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deletePageById = async (pageId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(DELETE, `/api/admin/help/${pageId}`, {}, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp ?? false;
};

export const getUserSettings = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(GET, `${route.api.user.settings}`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

export const updateUserSettings = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(PUT, `${route.api.user.settings}`, data, true);
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Add member to case team
 * @param caseId
 * @param data: {"email": "user@email.com"}
 * @param dispatch
 * @returns {Promise<*>}
 */
export const addMemberToCaseTeam = async (caseId, data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.caseTeams.addMember(caseId)}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get list of case members
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getCaseMembersById = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `${route.api.caseTeams.getMembersList(caseId)}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Delete case member from team
 * @param caseMemberId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const deleteCaseMemberById = async (caseMemberId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      DELETE,
      `${route.api.caseTeams.deleteMember(caseMemberId)}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};

/**
 * Get list of user invites
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getInvitesList = async (dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `${route.api.caseTeams.getInvitesList()}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Get email by token
 * @param token
 * @param dispatch
 * @returns {Promise<*>}
 */
export const getEmailByToken = async (token, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      GET,
      `${route.api.caseTeams.getEmail(token)}`,
      {},
      false
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Accept invite to case
 * @param data: {token: "TOKEN"}
 * @param dispatch
 * @returns {Promise<*>}
 */
export const acceptInvite = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.caseTeams.acceptInvite()}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
export const teamLimit = async (caseId, data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.caseTeams.getTeamLimitUrl(caseId)}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};
/**
 * Reject invite
 * @param data
 * @param dispatch
 * @returns {Promise<*>}
 */
export const rejectInvite = async (data, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.caseTeams.rejectInvite()}`,
      data,
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data && resp.data.data ? resp.data.data : false;
};

/**
 * Send request to admin, to increase size of user team
 * @param caseId
 * @param dispatch
 * @returns {Promise<*>}
 */
export const sendTeamSizeRequest = async (caseId, dispatch) => {
  let resp;
  try {
    resp = await makeRequest(
      POST,
      `${route.api.caseTeams.teamSizeRequest(caseId)}`,
      {},
      true
    );
  } catch (e) {
    modalErrorHandler(e, dispatch);
  }
  return resp && resp.data ? resp.data : false;
};
