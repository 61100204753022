import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import LeftSideBarUserMenuStyled from "./styled/LeftSideBarUserMenuStyled";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import Button from "../../buttons/Button";
import { withTheme } from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { HIDE_LEFT_SIDE_BAR } from "../../../redux/types";

const adminMenu = [
  {
    id: 1,
    title: "Logout",
    onClickHandler: (history) => history.push(route.auth.logOut),
    textColor: (theme) => theme.colors.burgundy,
  },
];

const LeftSideBarUserMenu = ({
  menuItems,
  closeHandler,
  idForHandler,
  theme,
  ...props
}) => {
  const isAdmin = useSelector((state) => state.auth.user.is_admin);
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const ref = useRef();
  const dispatch = useDispatch();
  useOnClickOutside(ref, closeHandler);

  const menuLayout = (menu) => (
    <Fragment>
      {menu.map((item) => (
        <div key={item.id} className="row">
          <div className="col">
            <Button
              tertiary
              clickHandler={() => {
                item.onClickHandler(history, idForHandler);
                closeHandler();
                dispatch({ type: HIDE_LEFT_SIDE_BAR });
              }}
            >
              <Bold14Font
                style={{
                  color: item.textColor
                    ? item.textColor(theme)
                    : theme.colors.blue,
                }}
              >
                {item.title === "Archive"
                  ? item.data.find((el) => el.id === idForHandler).archived
                    ? "Un-archive"
                    : "Archive"
                  : item.title}
              </Bold14Font>
            </Button>
          </div>
        </div>
      ))}
    </Fragment>
  );
  let items;
  if (isAdmin && !menuItems) {
    items = adminMenu;
  } else if (menuItems) {
    items = menuItems;
  } else {
    if (user.role === 'assistant') {
      items = [
        {
          id: 1,
          title: "Profile",
          onClickHandler: (history) => history.push(route.baseApp.settings),
        },
        {
          id: 3,
          title: "Logout",
          onClickHandler: (history) => history.push(route.auth.logOut),
          textColor: (theme) => theme.colors.burgundy,
        }
      ]
    } else {
      items = [
        {
          id: 1,
          title: "Profile",
          onClickHandler: (history) => history.push(route.baseApp.settings),
        },
        {
          id: 2,
          title: "Subscription",
          onClickHandler: (history) => history.push(route.baseApp.subscription.plans),
        },
        {
          id: 3,
          title: "Logout",
          onClickHandler: (history) => history.push(route.auth.logOut),
          textColor: (theme) => theme.colors.burgundy,
        }
      ];
    }
  }
  return (
    <LeftSideBarUserMenuStyled {...props} items={items.length} isAdmin={isAdmin} ref={ref}>
      <div className="h-100 d-flex flex-column justify-content-around align-items-start">
        {menuLayout(items)}
      </div>
    </LeftSideBarUserMenuStyled>
  );
};

LeftSideBarUserMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  closeHandler: PropTypes.func.isRequired,
};

export default withTheme(LeftSideBarUserMenu);
